import ProfilePicture from "./ProfilePicture";
import { useState, useEffect, useMemo } from 'react';
import { LoginForm } from "./login";
import { Tooltip } from "react-tooltip";
import CreateAccount from "./createAccount";
import { CloseButton } from "./closeButton";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import ProfileDrawer from './profileDrawer';

const Navbar = ({ }) => {
    const jwt = localStorage.getItem("token");
    const email = localStorage.getItem("email");
    const location = useLocation();
    const navigate = useNavigate();

    const { balance } = useAppContext();
    const profilePictureUrl = localStorage.getItem('profile_picture_url');

    // Event handlers for opening and closing modals
    const openLoginModal = () => {
        setLoginModalOpen(true);
        window.history.pushState(null, '', window.location.pathname);
    };
    const closeLoginModal = () => setLoginModalOpen(false);

    const openCreateAccountModal = () => {
        setCreateAccountModalOpen(true);
        window.history.pushState(null, '', window.location.pathname);
    };
    const closeCreateAccountModal = () => setCreateAccountModalOpen(false);

    const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
    const toggleProfileDrawer = () => {
        setIsProfileDrawerOpen(!isProfileDrawerOpen);
    };
    const closeProfileDrawer = () => {
        setIsProfileDrawerOpen(false);
    };

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);
    const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);

    useEffect(() => {
        if (isLoginModalOpen || isCreateAccountModalOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        const handlePopState = () => {
            if (isLoginModalOpen) {
                closeLoginModal();
            }
            if (isCreateAccountModalOpen) {
                closeCreateAccountModal();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isLoginModalOpen, isCreateAccountModalOpen]);

    const navbarContent = useMemo(() => (
        <div className="fixed z-20 w-screen flex-col">
            <ProfileDrawer isOpen={isProfileDrawerOpen} onClose={closeProfileDrawer} />
            <div className="h-20 relative justify-center shadow-md items-stretch self-stretch bg-white flex w-full flex-col p-4 sm:max-w-full sm:px-8">
                <div className="justify-between items-center flex w-full gap-5 flex-wrap">
                    <div>
                        <div onClick={() => {
                            if (location.pathname === '/') {
                                sessionStorage.removeItem('currentPage');
                                sessionStorage.removeItem('searchTerm');
                                window.location.reload();
                            }
                        }}>
                            <Link to={`/`}>
                                <img
                                    loading="lazy"
                                    src="/full_logo.svg"
                                    alt="divedeep logo"
                                    className="aspect-[5.31] object-contain object-center hidden sm:block sm:w-[186px] justify-center items-center overflow-hidden shrink-0 max-w-full my-auto"
                                />
                            </Link>
                            <Link to={`/`}>
                                <img
                                    loading="lazy"
                                    src="/dd-logo.svg"
                                    alt="divedeep logo"
                                    className="aspect-square object-contain object-center h-[40px] block sm:hidden justify-center items-center overflow-hidden shrink-0 max-w-full my-auto"
                                />
                            </Link>
                        </div>
                    </div>
                    {jwt ? (
                        <div className="select-none justify-between transition-all items-center self-stretch overflow-hide flex gap-1 cursor-pointer" onClick={toggleProfileDrawer}>
                            <div className="flex flex-col my-auto items-end" >
                                <div className="justify-end flex w-[31px] max-w-full gap-0">
                                    <div className="text-slate-600 text-right text-base font-semibold leading-6 grow whitespace-nowrap">
                                        {balance && Math.floor(balance).toLocaleString('en-US')}
                                    </div>
                                    <img
                                        loading="lazy"
                                        src="../creds.svg"
                                        alt="creds"
                                        className="aspect-square object-contain object-center w-full shrink-0 flex-1"
                                    />
                                </div>
                                <div className="text-zinc-600 text-right text-sm font-medium leading-5 self-stretch whitespace-nowrap">
                                    {email}
                                </div>
                            </div>
                            <div className="aspect-square object-contain object-center w-10 overflow-hidden self-stretch shrink-0 max-w-full"
                            >
                                <ProfilePicture url={profilePictureUrl} pseudoanon={false} />
                            </div>
                        </div >) : (<div className="justify-between items-stretch self-stretch flex gap-5">
                            <div
                                className="justify-between items-center shadow-sm bg-gray-100 flex gap-2 px-5 py-3 rounded-lg"
                                role="button"
                                aria-label="Login"
                                onClick={openLoginModal}
                            >
                                <img
                                    loading="lazy"
                                    src="../person.svg"
                                    className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full my-auto"
                                />
                                <div className="text-cyan-950 text-center text-base font-medium leading-6 self-stretch">
                                    Login
                                </div>
                            </div>
                            <div
                                className="justify-between items-center shadow-sm bg-gray-100 flex gap-2 px-5 py-3 rounded-lg"
                                role="button"
                                onClick={openCreateAccountModal}
                                aria-label="Signup"
                            >
                                <img
                                    loading="lazy"
                                    src="../newperson.svg"
                                    className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full my-auto"
                                />
                                <div className="text-cyan-950 text-center text-base font-medium leading-6 self-stretch">
                                    Signup
                                </div>
                            </div>
                        </div>)
                    }
                </div >
            </div >
        </div>
    ), [balance, profilePictureUrl, isProfileDrawerOpen, location.pathname, jwt, email]);

    return (
        <>
            {navbarContent}
            {isLoginModalOpen && (
                <div className="modal z-50 cursor-pointer" onClick={closeLoginModal}>
                    <div className="modal-content select-none cursor-default" onClick={(e) => e.stopPropagation()}>
                        <span className="close cursor-pointer" onClick={closeLoginModal}><CloseButton></CloseButton></span>
                        <LoginForm></LoginForm>
                    </div>
                </div>
            )}
            {isCreateAccountModalOpen && (
                <div className="modal z-50 cursor-pointer" onClick={closeCreateAccountModal}>
                    <div className="modal-content cursor-default" onClick={(e) => e.stopPropagation()}>
                        <span className="close cursor-pointer" onClick={closeCreateAccountModal}><CloseButton></CloseButton></span>
                        <CreateAccount></CreateAccount>
                    </div>
                </div>
            )}
        </>
    );
};

export default Navbar;