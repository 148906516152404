import React, { useState, useEffect, useRef } from 'react';
import Plot from 'react-plotly.js';
import { useAppContext } from '../AppContext';
import { toast } from 'react-toastify';

const apiRoot = process.env.REACT_APP_API_URL;
const jwt = localStorage.getItem("token");

const Databoard = () => {
    const [topicEmbeddings3D, setTopicEmbeddings3D] = useState([]);
    const [authd, setAuthd] = useState('loading');
    const [alphaHull, setAlphaHull] = useState(7);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const plotContainerRef = useRef(null);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchTopicEmbeddings = async () => {
            try {
                const response = await fetch(`${apiRoot}/topic/owned/embeddingplot?dimensions=3`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setTopicEmbeddings3D(data);
                    setAuthd(true);
                } else {
                    if (response.status === 401) {
                        setAuthd(false);
                    } else {
                        const responseBody = await response.text();
                        throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
                    }
                }
            } catch (error) {
                toast.error(`Error fetching 3D topic embeddings: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        fetchTopicEmbeddings();
    }, []);

    const createPlotData = (embeddings) => {
        return [
            {
                x: embeddings.map(t => t.embedding[0]),
                y: embeddings.map(t => t.embedding[1]),
                z: embeddings.map(t => t.embedding[2]),
                mode: 'markers',
                type: 'scatter3d',
                text: embeddings.map(t => t.title),
                hoverinfo: 'text',
                hoverlabel: { bgcolor: "#FFF", namelength: -1 },
                marker: {
                    color: 'rgb(23, 190, 207)',
                    size: 3,
                    opacity: 0.8,
                },
                hovertemplate: "%{text}",
            },
            {
                alphahull: alphaHull,
                opacity: 0.1,
                type: 'mesh3d',
                x: embeddings.map(t => t.embedding[0]),
                y: embeddings.map(t => t.embedding[1]),
                z: embeddings.map(t => t.embedding[2]),
                hoverinfo: 'skip',
            }
        ];
    };

    const layout = {
        autosize: true,
        scene: {
            xaxis: { showgrid: false, zeroline: false, showline: false, showticklabels: false, title: '', showspikes: false },
            yaxis: { showgrid: false, zeroline: false, showline: false, showticklabels: false, title: '', showspikes: false },
            zaxis: { showgrid: false, zeroline: false, showline: false, showticklabels: false, title: '', showspikes: false },
        },
        showlegend: false,
        hovermode: 'closest',
        margin: { l: 0, r: 0, b: 0, t: 0 },
    };

    if (authd === "loading" || authd === true) {
        return (
            <div className="w-full min-h-screen bg-white overflow-auto p-4">
                <div className="w-full mb-8" ref={plotContainerRef}>
                    <Plot
                        data={createPlotData(topicEmbeddings3D)}
                        layout={layout}
                        useResizeHandler={true}
                        style={{ width: "100%", height: windowWidth < 768 ? "400px" : "600px" }}
                        config={{ responsive: true, displayModeBar: false }}
                    />
                </div>
                <div className="w-full mb-4">
                    <label htmlFor="alphaHull" className="block text-sm font-medium text-gray-700">
                        Alpha Hull: {alphaHull}
                    </label>
                    <input
                        type="range"
                        id="alphaHull"
                        name="alphaHull"
                        min="1"
                        max="20"
                        value={alphaHull}
                        onChange={(e) => setAlphaHull(Number(e.target.value))}
                        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-full min-h-screen bg-white p-8">
                <p>You are not authorized to view this page.</p>
            </div>
        );
    }
};

export default Databoard;
