import { React, useState, useEffect } from 'react';
import { FiSend, FiInfo, FiFileText, FiBook, FiChevronDown, FiPlus, FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';

const apiRoot = process.env.REACT_APP_API_URL;
const jwt = localStorage.getItem("token");

const DiveBuddy = () => {
    const [agents, setAgents] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [query, setQuery] = useState('');
    const [responses, setResponses] = useState([]); // Store current chat history
    const [isLoading, setIsLoading] = useState(false);
    const [guidelinesExpanded, setGuidelinesExpanded] = useState({});
    const [researchExpanded, setResearchExpanded] = useState({});
    const [expandedDiagnoses, setExpandedDiagnoses] = useState({});
    const [expandedResponses, setExpandedResponses] = useState({});
    const [chats, setChats] = useState([]); // Store all chats
    const [selectedChatId, setSelectedChatId] = useState(null);

    useEffect(() => {
        // Load chats from localStorage
        const savedChats = localStorage.getItem('divebuddy-chats');
        let parsedChats = [];
        let initialChat;

        if (savedChats) {
            try {
                parsedChats = JSON.parse(savedChats);
            } catch (error) {
                console.error('Error parsing saved chats:', error);
            }
        }

        // Create initial chat if no valid chats exist
        if (!Array.isArray(parsedChats) || parsedChats.length === 0) {
            initialChat = {
                id: Date.now(),
                responses: [],
                createdAt: new Date().toISOString()
            };
            parsedChats = [initialChat];
            localStorage.setItem('divebuddy-chats', JSON.stringify(parsedChats));
        }

        setChats(parsedChats);
        setSelectedChatId(parsedChats[0].id);
        setResponses(parsedChats[0].responses);

        // Fetch available agents on component mount
        const fetchAgents = async () => {
            try {
                const response = await fetch(`${apiRoot}/divebuddy/chat`, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });
                
                if (response.ok) {
                    const data = await response.json();
                    setAgents(data);
                    if (data.length > 0) {
                        setSelectedAgent(data[0].id);
                    }
                } else {
                    toast.error('Failed to fetch agents');
                }
            } catch (error) {
                toast.error('Error fetching agents: ' + error.message);
            }
        };

        fetchAgents();
    }, []);

    // Save chats to localStorage whenever they change
    useEffect(() => {
        if (chats.length > 0) {
            localStorage.setItem('divebuddy-chats', JSON.stringify(chats));
        }
    }, [chats]);

    const createNewChat = () => {
        const newChat = {
            id: Date.now(),
            responses: [],
            createdAt: new Date().toISOString()
        };
        setChats(prevChats => [newChat, ...prevChats]);
        setSelectedChatId(newChat.id);
        setResponses([]);
    };

    const switchChat = (chatId) => {
        setSelectedChatId(chatId);
        const chat = chats.find(c => c.id === chatId);
        if (chat) {
            setResponses(chat.responses);
        }
    };

    const deleteChat = (chatId) => {
        setChats(prevChats => prevChats.filter(chat => chat.id !== chatId));
        if (selectedChatId === chatId) {
            const remainingChats = chats.filter(chat => chat.id !== chatId);
            if (remainingChats.length > 0) {
                switchChat(remainingChats[0].id);
            } else {
                createNewChat();
            }
        }
    };

    const deleteMessage = (index) => {
        const newResponses = [...responses];
        newResponses.splice(index, 1);
        setResponses(newResponses);
        
        // Update chat in chats array
        setChats(prevChats => prevChats.map(chat => 
            chat.id === selectedChatId 
                ? {...chat, responses: newResponses}
                : chat
        ));
    };
    const renderSources = (sources) => (
        <div className="space-y-4">
            {sources.map((source, index) => (
                <div key={index} className="bg-white rounded-lg shadow-sm p-4">
                    <div className="space-y-2">
                        {source.class_of_recommendation && (
                            <div className="flex items-center gap-2">
                                <span className="text-slate-600 font-medium">Recommendation:</span>
                                <span className="text-cyan-700 font-semibold">{source.class_of_recommendation}</span>
                            </div>
                        )}
                        {source.level_of_evidence && (
                            <div className="flex items-center gap-2">
                                <span className="text-slate-600 font-medium">Evidence Level:</span>
                                <span className="text-cyan-700 font-semibold">{source.level_of_evidence}</span>
                            </div>
                        )}
                        {source.text && (
                            <div className="bg-slate-50 rounded-lg p-4">
                                <p className="text-gray-700 text-sm leading-relaxed">{source.text}</p>
                            </div>
                        )}
                        {source.citation && (
                            <p className="text-cyan-600 text-sm italic">{source.citation}</p>
                        )}
                        {source.source_document_title && (
                            <div className="text-sm space-y-1 border-t border-slate-100 pt-2 mt-2">
                                <p className="font-medium text-cyan-800">{source.source_document_title}</p>
                                {source.authors && <p className="text-slate-600">{source.authors}</p>}
                                {source.journal && (
                                    <p className="text-slate-600">
                                        {source.journal} ({source.year_of_publication || source.year})
                                    </p>
                                )}
                                {source.PubMed_link && (
                                    <a 
                                        href={source.PubMed_link} 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center text-cyan-600 hover:text-cyan-800 transition-colors"
                                    >
                                        View on PubMed
                                        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedAgent) {
            toast.warning('Please select an agent');
            return;
        }

        setIsLoading(true);
        try {
            const chatHistory = [
                ...responses.map(r => ({
                    role: r.type === 'user' ? 'user' : 'assistant', 
                    content: typeof r.content === 'object' ? JSON.stringify(r.content) : r.content
                }))
            ];

            if (query.trim()) {
                chatHistory.push({
                    role: 'user',
                    content: query
                });
            }

            const response = await fetch(`${apiRoot}/divebuddy/chat?agent_id=${selectedAgent}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify({ chat_history: chatHistory })
            });

            if (response.ok) {
                const data = await response.json();
                
                const newResponses = [
                    ...responses,
                    ...(query.trim() ? [{
                        type: 'user',
                        content: query,
                        timestamp: new Date()
                    }] : []),
                    {
                        type: 'assistant',
                        content: data,
                        timestamp: new Date()
                    }
                ];
                
                // Update responses state
                setResponses(newResponses);
                
                // Update chat in chats array
                setChats(prevChats => prevChats.map(chat => 
                    chat.id === selectedChatId 
                        ? {...chat, responses: newResponses}
                        : chat
                ));
                
                setQuery(''); // Clear input after successful response
            } else {
                toast.error('Failed to get response from agent');
            }
        } catch (error) {
            toast.error('Error communicating with agent: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAgentSelect = (agentId) => {
        console.log("Selected agent ID:", agentId);
        setSelectedAgent(agentId);
    };

    const renderAIResponse = (response) => {
        const { guidelines, research_summary, differential_diagnosis } = response.content;

        return (
            <div>
                {differential_diagnosis && differential_diagnosis.differential_diagnoses && (
                    <div className="flex-col gap-4 flex-grow flex-shrink-0 basis-full bg-white rounded-lg shadow-md h-auto">
                        <h4 className="rounded-t-lg p-4 text-xl bg-cyan-800 font-semibold text-slate-100 flex items-center">
                            <div className="flex select-none items-center">
                                Differential Diagnosis
                            </div>
                        </h4>
                        <div className="p-4">
                            {differential_diagnosis.aggregated_statement && (
                                <p className="text-gray-700 mb-4">{differential_diagnosis.aggregated_statement}</p>
                            )}
                            <div className="space-y-4">
                                {differential_diagnosis.differential_diagnoses.map((diagnosis, index) => (
                                    <div
                                        key={index}
                                        className={`rounded-lg p-4 cursor-pointer ${
                                            index === 0 ? 'bg-cyan-800' : 'bg-slate-50'
                                        }`}
                                        onClick={() => {
                                            const newExpandedDiagnoses = { ...expandedDiagnoses };
                                            newExpandedDiagnoses[`${response.timestamp}-${index}`] = !newExpandedDiagnoses[`${response.timestamp}-${index}`];
                                            setExpandedDiagnoses(newExpandedDiagnoses);
                                        }}
                                    >
                                        <div className="flex-grow">
                                            <div className="flex items-center">
                                                <h5 className={`text-lg ${index === 0 ? 'text-slate-100' : 'text-cyan-900'} font-semibold select-none flex justify-between items-center flex-grow`}>
                                                    <span className="flex-grow break-words">{diagnosis.diagnosis}</span>
                                                    <FiChevronDown 
                                                        className={`h-6 w-6 ml-2 flex-shrink-0 transition-transform ${expandedDiagnoses[`${response.timestamp}-${index}`] ? 'transform rotate-180' : ''}`} 
                                                    />
                                                </h5>
                                            </div>
                                            <div 
                                                className={`overflow-hidden transition-all duration-300 ${expandedDiagnoses[`${response.timestamp}-${index}`] ? 'max-h-[1000px] mt-4 opacity-100' : 'h-0 opacity-0'}`}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <div className={`cursor-default ${index === 0 ? 'text-slate-100' : 'text-gray-700'}`}>
                                                    <p className="mb-2"><strong>History Queries:</strong> {diagnosis.suggested_history_queries}</p>
                                                    <p className="mb-2"><strong>Lab Testing:</strong> {diagnosis.suggested_laboratory_testing}</p>
                                                    <p className="mb-2"><strong>Imaging Studies:</strong> {diagnosis.suggested_imaging_studies}</p>
                                                    <p className="mb-2"><strong>Biopsy Required:</strong> {diagnosis.biopsy_required ? 'Yes' : 'No'}</p>
                                                    {diagnosis.biopsy_required && (
                                                        <p className="mb-2"><strong>Biopsy Type:</strong> {diagnosis.biopsy_type}</p>
                                                    )}
                                                    {diagnosis.specialty_consultants && diagnosis.specialty_consultants.length > 0 && (
                                                        <p className="mb-2">
                                                            <strong>Specialty Consultants:</strong> {diagnosis.specialty_consultants.join(', ')}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {guidelines && (
                    <div className="flex-col gap-4 flex-grow flex-shrink-0 basis-full sm:basis-[calc(50%-1rem)] bg-white rounded-lg shadow-md h-auto">
                        <h4 className="rounded-t-lg p-4 text-xl bg-cyan-800 font-semibold text-slate-100 flex items-center">
                            <div className="flex select-none items-center">
                                <FiInfo className="h-6 w-6 mr-2 text-slate-100" />
                                Guidelines
                            </div>
                        </h4>
                        <div className="p-4 space-y-8">
                            {Object.entries(guidelines).map(([specialty, data]) => (
                                !data.out_of_scope && data.statements && data.statements.length > 0 && (
                                    <div key={specialty} className="rounded-md max-w-xl mb-4">
                                        <h5 className="text-lg text-cyan-900 font-semibold mb-4">{specialty}</h5>
                                        {data.statements.map((statement, index) => (
                                            <div key={index} className="mb-6">
                                                <p className="text-gray-700 mb-3">{statement.aggregated_statement}</p>
                                                {statement.sources && statement.sources.length > 0 && (
                                                    <div className="text-sm text-cyan-600">
                                                        <button
                                                            onClick={() => {
                                                                const newGuidelinesExpanded = { ...guidelinesExpanded };
                                                                newGuidelinesExpanded[`${response.timestamp}-${specialty}-${index}`] = !newGuidelinesExpanded[`${response.timestamp}-${specialty}-${index}`];
                                                                setGuidelinesExpanded(newGuidelinesExpanded);
                                                            }}
                                                            className="font-semibold text-cyan-800 flex items-center w-full justify-between"
                                                        >
                                                            <span className="flex items-center">
                                                                <FiBook className="h-5 w-5 mr-2 text-cyan-600" />
                                                                Sources
                                                            </span>
                                                            <FiChevronDown className={`h-5 w-5 transition-transform ${guidelinesExpanded[`${response.timestamp}-${specialty}-${index}`] ? 'transform rotate-180' : ''}`} />
                                                        </button>
                                                        {guidelinesExpanded[`${response.timestamp}-${specialty}-${index}`] && renderSources(statement.sources)}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                )}

                {research_summary && research_summary.summary && (
                    <div className="flex-col gap-4 flex-grow flex-shrink-0 basis-full sm:basis-[calc(50%-1rem)] bg-white rounded-lg shadow-md h-auto">
                        <h4 className="rounded-t-lg p-4 text-xl bg-cyan-800 font-semibold text-slate-100 flex items-center">
                            <div className="flex select-none items-center">
                                <FiFileText className="h-6 w-6 mr-2 text-slate-100" />
                                Research Summary
                            </div>
                        </h4>
                        <div className="p-4 space-y-4">
                            {Array.isArray(research_summary.summary.statements) ? (
                                research_summary.summary.statements.map((item, index) => (
                                    <div key={index} className="rounded-md max-w-xl">
                                        <p className="text-gray-700 mb-3">{item.statement}</p>
                                        {item.citations && item.citations.length > 0 && (
                                            <div className="text-sm text-cyan-600">
                                                <button
                                                    onClick={() => {
                                                        const newResearchExpanded = { ...researchExpanded };
                                                        newResearchExpanded[`${response.timestamp}-${index}`] = !newResearchExpanded[`${response.timestamp}-${index}`];
                                                        setResearchExpanded(newResearchExpanded);
                                                    }}
                                                    className="font-semibold text-cyan-800 flex items-center w-full justify-between"
                                                >
                                                    <span className="flex items-center">
                                                        <FiBook className="h-5 w-5 mr-2 text-cyan-600" />
                                                        Sources
                                                    </span>
                                                    <FiChevronDown className={`h-5 w-5 transition-transform ${researchExpanded[`${response.timestamp}-${index}`] ? 'transform rotate-180' : ''}`} />
                                                </button>
                                                {researchExpanded[`${response.timestamp}-${index}`] && renderSources(item.citations)}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="rounded-md max-w-xl">
                                    <p className="text-gray-700 mb-3">{research_summary.summary.statement}</p>
                                    {research_summary.summary.citations && research_summary.summary.citations.length > 0 && (
                                        <div className="text-sm text-cyan-600">
                                            <button
                                                onClick={() => {
                                                    const newResearchExpanded = { ...researchExpanded };
                                                    newResearchExpanded[`${response.timestamp}`] = !newResearchExpanded[`${response.timestamp}`];
                                                    setResearchExpanded(newResearchExpanded);
                                                }}
                                                className="font-semibold text-cyan-800 flex items-center w-full justify-between"
                                            >
                                                <span className="flex items-center">
                                                    <FiBook className="h-5 w-5 mr-2 text-cyan-600" />
                                                    Sources
                                                </span>
                                                <FiChevronDown className={`h-5 w-5 transition-transform ${researchExpanded[`${response.timestamp}`] ? 'transform rotate-180' : ''}`} />
                                            </button>
                                            {researchExpanded[`${response.timestamp}`] && renderSources(research_summary.summary.citations)}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="container bg-slate-100 mx-auto px-4 py-8">
            <div className="flex flex-wrap gap-4 mb-6">
                <button
                    onClick={createNewChat}
                    className="flex items-center bg-cyan-600 text-white px-4 py-2 rounded hover:bg-cyan-700"
                >
                    <FiPlus className="mr-2" />
                    New Chat
                </button>
                <div className="flex flex-wrap gap-2">
                    {chats.map((chat) => (
                        <div key={chat.id}>
                            <button
                                className={`px-4 py-2 rounded flex items-center gap-2 ${
                                    selectedChatId === chat.id
                                        ? 'bg-cyan-600 text-white'
                                        : 'bg-white text-gray-700 hover:bg-gray-100'
                                }`}
                            >
                                <span onClick={() => switchChat(chat.id)}>
                                    {new Date(chat.createdAt).toLocaleDateString()}
                                </span>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        deleteChat(chat.id);
                                    }}
                                    className="p-1 text-red-500 hover:text-red-600 rounded"
                                >
                                    <FiTrash2 className="h-4 w-4" />
                                </button>
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mb-6 overflow-y-auto">
                {responses.map((response, index) => (
                    <div key={index} className={`mb-4 ${response.type === 'user' ? 'text-right' : 'text-left'} relative group flex items-center gap-2`}>
                        {response.type === 'user' && (
                            <button
                                onClick={() => deleteMessage(index)}
                                className="p-1 bg-red-500 text-white rounded-full ml-auto"
                            >
                                <FiTrash2 className="h-4 w-4" />
                            </button>
                        )}
                        <div className={`inline-block ${response.type === 'user' ? 'p-3' : 'p-0'} rounded-lg sm:max-w-[80%] max-w-full ${
                            response.type === 'user' 
                                ? 'bg-cyan-600 text-white' 
                                : 'bg-white shadow-md'
                        }`}>
                            {response.type === 'user' ? (
                                <p className="break-words">{response.content}</p>
                            ) : (
                                renderAIResponse(response)
                            )}
                        </div>
                        {response.type !== 'user' && (
                            <button
                                onClick={() => deleteMessage(index)}
                                className="p-1 bg-red-500 text-white rounded-full"
                            >
                                <FiTrash2 className="h-4 w-4" />
                            </button>
                        )}
                    </div>
                ))}
            </div>

            <form onSubmit={handleSubmit} className="mb-6">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        Your Question:
                    </label>
                    <div className="flex">
                        <input
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            className="flex-grow p-2 border rounded-l focus:outline-none focus:border-cyan-500"
                            placeholder="Enter your medical question..."
                            disabled={isLoading || !selectedAgent}
                        />
                        <button
                            type="submit"
                            className="bg-cyan-600 text-white px-4 py-2 rounded-r hover:bg-cyan-700 focus:outline-none flex items-center disabled:bg-gray-400"
                            disabled={isLoading || !selectedAgent}
                        >
                            <FiSend className="mr-2" />
                            {isLoading ? 'Thinking...' : 'Send'}
                        </button>
                    </div>
                </div>
            </form>
            <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Select an AI Assistant:
                </label>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {agents.map((agent) => (
                        <div
                            key={agent.id}
                            className={`p-4 border rounded-lg cursor-pointer transition-colors ${
                                selectedAgent === agent.id 
                                    ? 'border-blue-500 bg-blue-900 text-white' 
                                    : 'border-gray-200 hover:border-blue-300'
                            }`}
                            onClick={() => handleAgentSelect(agent.id)}
                        >
                            <h3 className="font-bold">{agent.display_name}</h3>
                            <p className={`text-sm ${selectedAgent === agent.id ? 'text-white' : 'text-gray-600'}`}>
                                {agent.description}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default DiveBuddy;
