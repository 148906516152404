import * as React from "react";
import ProfilePicture from "./ProfilePicture";
import TimeSince from "./timeSince";
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { useAppContext } from "../AppContext.js";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { CloseButton } from "./closeButton.jsx";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { Remark } from 'react-remark';
import UpdateTopicModal from "./topicUpdateModal.jsx";
import { FaLock } from "react-icons/fa"
import ReactMarkdown from "react-markdown";

const jwt = localStorage.getItem("token");


const SkeletonLoader = () => {
    return (
        <div className="items-stretch border-b-2 border-solid py-5">
            <div className="flex gap-0 justify-between items-stretch">
                <div className="skeleton skeleton-animation w-8 h-8 rounded-full"></div>
                <div className="skeleton skeleton-animation w-24 h-6"></div>
            </div>
            <div className="skeleton skeleton-animation mt-3 h-6 w-full"></div>
            <div className="skeleton skeleton-animation mt-2 h-4 w-full"></div>
            <div className="flex gap-4 mt-3">
                <div className="skeleton skeleton-animation w-6 h-6"></div>
                <div className="skeleton skeleton-animation w-6 h-6"></div>
                <div className="skeleton skeleton-animation w-6 h-6"></div>
            </div>
        </div>
    );
};

const TopicCard = ({ topic, highlight = [], refresh, expanded }) => {



    const { communityConfig } = useAppContext();
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(true);
    // State to manage modal visibility
    const [isUpdateQuestionModalOpen, setUpdateQuestionModalOpen] = useState(false);
    const [subdaoName, setSubdaoName] = useState('')
    const openUpdateQuestionModal = () => {
        setUpdateQuestionModalOpen(true);
        window.history.pushState(null, "", window.location.pathname);
    };
    const closeUpdateQuestionModal = () => setUpdateQuestionModalOpen(false);
    const [isBodyExpanded, setIsBodyExpanded] = useState(false);
    const [isTitleExpanded, setIsTitleExpanded] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();



    useEffect(() => {
        if (topic != null) {
            setIsBookmarked(topic.bookmarked);
            setIsAnonymous(!(topic.creator_name && topic.creator_address_id));
            if (communityConfig && communityConfig[topic.subdao_id]) {
                setSubdaoName(communityConfig[topic.subdao_id].name);
            }
        }
    }, [topic]);

    useEffect(() => {
        const handlePopState = () => {
            if (isUpdateQuestionModalOpen) {
                closeUpdateQuestionModal();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isUpdateQuestionModalOpen]);
    if (!topic) {
        return <SkeletonLoader />
    }
    const isTopicPage = location.pathname.startsWith('/topic/') && location.pathname.split('/')[2] === topic.id.toString();
    const ReadMore = ({ topic, field }) => {
        const [isExpanded, setIsExpanded] = useState(field === 'title' ? isTitleExpanded : isBodyExpanded);

        const toggleExpanded = () => {
            if (field === 'title') {
                setIsTitleExpanded(!isTitleExpanded);
            } else {
                setIsBodyExpanded(!isBodyExpanded);
            }
        };

        if (!topic[field]) {
            topic[field] = "";
        }

        const isTruncated = topic[field].length > 180;

        const truncateAtSpace = (str, maxLength) => {
            if (str.length <= maxLength) return str;
            const truncated = str.substr(0, str.lastIndexOf(' ', maxLength));
            return truncated + "...";
        };
        return (
            <div className="text-slate-800 text-base leading-6 max-md:max-w-full whitespace-pre-wrap">
                <ReactMarkdown
                    components={{
                        img: ({node, ...props}) => (
                            isExpanded ? <img style={{maxHeight: '200px'}} {...props} /> : null
                        ),
                    }}
                >
                    {isExpanded || !isTruncated ?
                        topic[field] :
                        truncateAtSpace(topic[field], 180)
                    }
                </ReactMarkdown>
                {isTruncated && (
                    <span onClick={(event) => {
                        event.stopPropagation();
                        toggleExpanded();
                    }} className="font-semibold text-sm leading-5 text-cyan-600 cursor-pointer">
                        {isExpanded ? "Read less" : "...Read more"}
                    </span>
                )}
            </div>
        );
    };
    const toggleBookmark = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/topics/bookmark?topic_id=${topic.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
            });
            if (response.ok) {
                setIsBookmarked(!isBookmarked);
                // Optionally, trigger a toast or notification to the user
            } else {
                console.error('Failed to toggle bookmark');
                // Handle error, possibly showing a message to the user
            }
        } catch (error) {
            console.error('Error toggling bookmark:', error);
            // Handle error, possibly showing a message to the user
        }
    };

    const toggleAnonymity = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/topic/anonymous?topic_id=${topic.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
                body: JSON.stringify({ anonymous: !isAnonymous }),
            });
            if (response.ok) {
                const data = await response.json();
                setIsAnonymous(data.anonymous)
                topic.creator_address_id = data.creator_address_id;
                topic.creator_profile_picture_url = data.profile_picture_url;
                topic.creator_name = data.creator_name;
                toast.dismiss(); // Dismiss any existing toasts
                toast.success(`Your identity on this topic is now ${data.anonymous ? 'anonymous' : 'public'}`);
            } else {
                console.error('Failed to toggle anonymity');
                toast.dismiss(); // Dismiss any existing toasts
                toast.error('Failed to toggle anonymity');
            }
        } catch (error) {
            console.error('Error toggling anonymity:', error);
            toast.dismiss(); // Dismiss any existing toasts
            toast.error('Error toggling anonymity');
        }
    };

    const handleRefundTopic = async () => {
        const confirmRefund = window.confirm("Are you sure you want to delete this topic? This action cannot be undone, you will be refunded the creds spent to create the topic.");
        if (confirmRefund) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/topic?topic_id=${topic.id}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    },
                });

                if (response.ok) {
                    toast.success("Topic deleted successfully!");
                    navigate('/'); // Redirect to home page or wherever appropriate

                } else {
                    const errorData = await response.json();
                    throw new Error(errorData.message || "Failed to refund topic");
                }
            } catch (error) {
                toast.error(`Error refunding topic: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const opacity = 1; // Adjust opacity as needed
    const baseHue = subdaoName.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % 300;
    const hashColor = `hsla(${baseHue}, 20%, 50%, ${opacity})`;



    return (
        <div key={topic.id ? topic.id : "topic"} className={`items-stretch h-full flex flex-col gap-1 justify-between py-4`}>
            <div className="flex items-center justify-between mb-3 gap-4">
                <div className="flex items-center flex justify-between pr-2 gap-2">
                    <div className="flex items-center justify-center">
                        <div className="rounded-full object-contain object-center w-8 h-8 flex justify-center items-center overflow-hidden shrink-0">
                            <ProfilePicture
                                userAddressId={topic.creator_address_id}
                                url={topic.creator_profile_picture_url}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className={`text-sm leading-5 self-center grow my-auto ${topic.creator_name ? 'text-zinc-800 font-medium' : 'italic text-zinc-500'}`}>
                            {topic.creator_name ? topic.creator_name : "Anonymous"}
                        </div>
                        {topic.owned && (
                            <span className="bg-cyan-800 select-none text-center rounded-full text-zinc-100 px-2 py-1 text-xs">You</span>
                        )}
                        <div className="text-neutral-500 text-right text-sm leading-5 whitespace-nowrap">
                            <TimeSince timestamp={topic.created_at} />
                        </div>
                    </div>
                </div>
                {Object.keys(communityConfig).length > 1 && subdaoName && (
                    <div className="flex text-slate-100 text-center text-sm font-bold h-7 px-2.5 items-center rounded-3xl ml-auto" style={{ backgroundColor: hashColor }}>
                        {subdaoName}
                    </div>
                )}
            </div>
            <div className="flex-col gap-2">
                <div className="text-slate-800 text-xl mb-1 font-semibold leading-8">
                    {highlight[0] && highlight[0].length > 3 ? (
                        <Highlighter
                            highlightClassName="p-0 bg-[#bad8de] rounded-md"
                            searchWords={highlight}
                            autoEscape={true}
                            textToHighlight={topic.title}
                        />
                    ) : (
                        expanded ? (
                            <ReactMarkdown>{topic.title}</ReactMarkdown>
                        ) : (
                            <ReadMore topic={topic} field="title" />
                        )
                    )}
                </div>
                {topic.body.length > 0 && (
                    <div className="text-slate-800 text-base leading-6">
                        {highlight[0] && highlight[0].length > 3 ? (
                            <Highlighter
                                highlightClassName="p-0 bg-[#bad8de] rounded-md"
                                searchWords={highlight}
                                autoEscape={true}
                                textToHighlight={topic.body}
                            />
                        ) : (
                            expanded ? (
                                <ReactMarkdown
                                    components={{
                                        img: ({node, ...props}) => (
                                            <img style={{maxHeight: '312px'}} {...props} />
                                        ),
                                    }}
                                >
                                    {topic.body}
                                </ReactMarkdown>
                            ) : (
                                <ReadMore topic={topic} field="body" />
                            )
                        )}
                    </div>
                )}
            </div>
            <div className="mb-1"></div>
            <div className="flex w-full items-center select-none justify-between gap-4">
                <div className="flex justify-between gap-0.5">
                    <img
                        loading="lazy"
                        src="../eye.svg"
                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                    />
                    <div className="text-neutral-500 text-right text-sm font-medium leading-5 self-center grow whitespace-nowrap my-auto">
                        {topic.accesses}
                    </div>
                </div>
                <div className="flex justify-between gap-0.5">
                    <img
                        loading="lazy"
                        src="../messages.svg"
                        className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full"
                    />
                    <div className="text-neutral-500 text-right text-sm font-medium leading-5 self-center grow whitespace-nowrap my-auto">
                        {topic.responses}
                    </div>
                </div>
                <div className="flex cursor-pointer justify-between gap-0.5" onClick={(event) => {
                    event.stopPropagation();
                    toggleBookmark();
                }} data-tooltip-id="bookmark-tooltip">
                    <img
                        loading="lazy"
                        src={isBookmarked ? "../bookmark-filled.svg" : "../bookmark.svg"}
                        className={`aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full ${isBookmarked ? "fill-current text-yellow-500" : ""}`}
                    />
                    <Tooltip id="bookmark-tooltip" content={isBookmarked ? "" : "Bookmark to receive email updates on responses"} place="right" />
                </div>
                <div className="flex-grow"></div>
                {!topic.authd && (
                    <div className="flex items-center">
                        <FaLock className="text-cyan-800 opacity-25 w-5 h-5" />
                    </div>
                )}
                {Math.floor(topic.per_expert_payout) > 0 && topic.expert && (
                    <div className="flex items-center">
                        <div className="flex items-center bg-cyan-700 h-7 pl-2.5 pr-1.5 rounded-l-full text-slate-100 text-xs whitespace-nowrap">
                            {topic.bounties_left + " tip" + (topic.bounties_left !== 1 ? "s" : "") + " left"}
                        </div>
                        <div className="flex bg-cyan-800 items-center h-7 pl-1.5 pr-0.5 rounded-r-full">
                            <div className={`flex text-slate-100 text-md font-bold whitespace-nowrap items-center`}>
                                +
                                {Math.floor(topic.per_expert_payout)}
                            </div>
                            <img
                                loading="lazy"
                                src="../creds.svg"
                                className="aspect-square mt-[0.05rem] object-contain object-center w-6 justify-center items-center shrink-0"
                            />
                        </div>
                    </div>
                )}


            </div>
            {isTopicPage && (
                <>

                    {topic.owned && (
                        <div className="flex flex-wrap items-center gap-2 mt-6 mb-4">
                            <div className={`flex gap-2 p-2 items-center rounded ${isAnonymous ? 'bg-cyan-900 text-slate-100' : 'bg-slate-200 text-slate-900 border border-slate-300'}`}>
                                <label className="relative inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={isAnonymous}
                                        onChange={(e) => {
                                            const newValue = e.target.checked;
                                            const message = newValue
                                                ? "Are you sure you want to make your profile anonymous? Your name and profile picture on this topic will be hidden from everyone."
                                                : "Are you sure you want to make your profile public? Your name and profile picture on this topic will be visible to everyone.";
                                            if (window.confirm(message)) {
                                                toggleAnonymity(e);
                                            } else {
                                                e.preventDefault();
                                            }
                                        }}
                                        className="sr-only peer"
                                    />
                                    <div className="w-11 h-6 bg-slate-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-cyan-800"></div>
                                </label>
                                <span>Anonymous</span>
                            </div>
                            <div className="flex-grow" />
                            {topic.responses === 0 && (
                                <>
                                    <div onClick={openUpdateQuestionModal} data-tooltip-id="edit-tooltip" className="flex gap-2 px-3 py-2 items-center rounded bg-slate-200 text-slate-900 border border-slate-300 cursor-pointer">
                                        <HiOutlinePencilSquare className="w-6 h-6" />
                                        <span>Edit</span>
                                        <Tooltip id="edit-tooltip" content="You may edit your question up until someone responds" place="left" />
                                    </div>
                                    <button
                                        onClick={handleRefundTopic}
                                        className="flex gap-2 px-3 py-2 items-center rounded bg-red-700 text-white hover:bg-red-600 transition duration-300"
                                    >
                                        Delete
                                    </button>
                                </>
                            )}
                        </div>
                    )}


                </>
            )}
            {isUpdateQuestionModalOpen && (
                <div 
                    className="modal z-50 cursor-pointer" 
                    onMouseDown={(e) => {
                        if (e.target === e.currentTarget) {
                            closeUpdateQuestionModal();
                        }
                    }}
                >
                    <div className="modal-content cursor-default" onClick={(e) => e.stopPropagation()}>
                        <span className="close cursor-pointer" onClick={closeUpdateQuestionModal}><CloseButton></CloseButton></span>
                        <UpdateTopicModal topic={topic} onClose={() => {
                            closeUpdateQuestionModal();
                            refresh();
                        }} />
                    </div>
                </div>
            )}
        </div >
    )
};

export default TopicCard;
