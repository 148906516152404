import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FiPlus, FiEdit2, FiTrash2, FiLoader, FiUpload } from 'react-icons/fi';
import { useAppContext } from '../AppContext.js';
import { pdfjs } from 'react-pdf';

const apiRoot = process.env.REACT_APP_API_URL;

const Divebuddies = () => {
    const { communityConfig } = useAppContext();
    const [subdaoId, setSubdaoId] = useState(() => {
        const saved = localStorage.getItem('divebuddies-subdao');
        return saved ? Number(saved) : null;
    });
    const [bots, setBots] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editingBot, setEditingBot] = useState(null);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [models, setModels] = useState([]);
    const [formData, setFormData] = useState({
        bot_name: '',
        prompt: '',
        provider: 'claude',
        model: ''
    });
    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [botDocuments, setBotDocuments] = useState({});
    const jwt = localStorage.getItem("token");

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
    }, []);

    useEffect(() => {
        if (subdaoId) {
            fetchBots();
            localStorage.setItem('divebuddies-subdao', subdaoId.toString());
        }
    }, [subdaoId]);

    useEffect(() => {
        const fetchProviders = async () => {
            try {
                const response = await fetch(`${apiRoot}/bots/llm/providers`, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch providers');
                const data = await response.json();
                setProviders(data.providers || []);
                
                // Set default provider and model
                const defaultProvider = data.providers.find(p => p.default);
                if (defaultProvider) {
                    setFormData(prev => ({
                        ...prev,
                        provider: defaultProvider.id,
                        model: defaultProvider.models[0] || ''
                    }));
                }
            } catch (error) {
                toast.error(error.message);
            }
        };
        fetchProviders();
    }, [jwt]);

    const handleProviderChange = (e) => {
        const providerId = e.target.value;
        const provider = providers.find(p => p.id === providerId);
        setFormData(prev => ({
            ...prev,
            provider: providerId,
            model: provider?.models?.[0] || ''
        }));
    };

    const handleModelChange = (e) => {
        setFormData(prev => ({
            ...prev,
            model: e.target.value
        }));
    };

    useEffect(() => {
        const fetchModels = async () => {
            try {
                const response = await fetch(`${apiRoot}/bots/models`, {
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });
                if (!response.ok) throw new Error('Failed to fetch models');
                const modelsList = await response.json();
                setModels(modelsList);
                setFormData(prev => ({
                    ...prev,
                    model: modelsList[0]
                }));
            } catch (error) {
                toast.error(error.message);
            }
        };
        fetchModels();
    }, [jwt]);

    useEffect(() => {
        const fetchBotDocuments = async () => {
            if (!bots.length) return;

            const documentsPromises = bots.map(async (bot) => {
                try {
                    const response = await fetch(`${apiRoot}/documents/list?bot_id=${bot.id}`, {
                        headers: {
                            'Authorization': `Bearer ${jwt}`
                        }
                    });
                    if (!response.ok) throw new Error('Failed to fetch bot documents');
                    const data = await response.json();
                    return { [bot.id]: data.filenames || [] };
                } catch (error) {
                    console.error(error);
                    return { [bot.id]: [] };
                }
            });

            const results = await Promise.all(documentsPromises);
            const documentsMap = Object.assign({}, ...results);
            setBotDocuments(documentsMap);
        };

        fetchBotDocuments();
    }, [bots, jwt]);

    const fetchBots = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${apiRoot}/bots/list?subdao_id=${subdaoId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch bots');
            const data = await response.json();
            setBots(data.bots || []);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (e, botId) => {
        const selectedFiles = Array.from(e.target.files);
        const pdfFiles = selectedFiles.filter(file => file.type === 'application/pdf');
        
        if (pdfFiles.length !== selectedFiles.length) {
            toast.error('Only PDF files are allowed');
        }
        
        setFiles(prev => ({ ...prev, [botId]: pdfFiles }));
    };

    const handleDeleteDocument = async (botId, filename) => {
        try {
            const response = await fetch(`${apiRoot}/documents/delete?bot_id=${botId}&filename=${filename}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            });

            if (!response.ok) throw new Error('Failed to delete document');

            toast.success('Document deleted successfully');
            setBotDocuments(prev => ({
                ...prev,
                [botId]: prev[botId].filter(doc => doc !== filename)
            }));
        } catch (error) {
            toast.error(error.message || 'Failed to delete document');
        }
    };

    const handleUpload = async (botId) => {
        if (!files[botId]?.length || !subdaoId) return;

        const bot = bots.find(b => b.id === botId);
        if (!bot) return;

        setIsUploading(true);
        try {
            const uploadPromises = files[botId].map(async (file) => {
                const arrayBuffer = await file.arrayBuffer();
                const loadingTask = pdfjs.getDocument({
                    data: arrayBuffer,
                    disableRange: true,
                    disableAutoFetch: true,
                    disableStream: true
                });
                const pdf = await loadingTask.promise;
                
                // Get metadata
                const metadata = await pdf.getMetadata();
                
                let fullText = '';
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const textContent = await page.getTextContent();
                    const pageText = textContent.items
                        .map(item => item.str)
                        .join(' ')
                        .replace(/\s+/g, ' ')
                        .trim();
                    fullText += pageText + '\n';
                }

                const response = await fetch(`${apiRoot}/documents/intake`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        subdao_name: communityConfig[subdaoId].name,
                        bot_id: botId,
                        document_name: file.name,
                        content: fullText,
                        metadata: {
                            info: metadata?.info || {},
                            contentLength: fullText.length,
                            pageCount: pdf.numPages
                        }
                    })
                });

                if (!response.ok) {
                    const error = await response.json();
                    throw new Error(error.message || 'Upload failed');
                }
            });

            await Promise.all(uploadPromises);

            toast.success('Documents uploaded successfully');
            setFiles(prev => ({ ...prev, [botId]: [] }));
            
            // Refresh documents list
            const response = await fetch(`${apiRoot}/documents/list?bot_id=${botId}&subdao_name=${communityConfig[subdaoId].name}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch updated documents');
            const data = await response.json();
            setBotDocuments(prev => ({
                ...prev,
                [botId]: data.filenames || []
            }));

        } catch (error) {
            toast.error(error.message || 'Failed to upload documents');
        } finally {
            setIsUploading(false);
        }
    };

    const handleEditClick = (bot) => {
        setEditingBot(bot);
        setFormData({
            bot_name: bot.bot_name,
            prompt: bot.prompt,
            model: bot.model,
            provider: bot.provider || 'claude'
        });
        setShowModal(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const endpoint = editingBot ? 'update' : 'create';
        const method = editingBot ? 'PUT' : 'POST';
        
        try {
            const response = await fetch(`${apiRoot}/bots/${endpoint}`, {
                method,
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...formData,
                    subdao_id: subdaoId,
                    ...(editingBot && { id: editingBot.id })
                })
            });

            if (!response.ok) throw new Error(`Failed to ${endpoint} bot`);
            
            toast.success(`Bot ${editingBot ? 'updated' : 'created'} successfully`);
            setShowModal(false);
            setEditingBot(null);
            setFormData({
                bot_name: '',
                prompt: '',
                provider: providers.find(p => p.default)?.id || 'claude',
                model: providers.find(p => p.default)?.models?.[0] || ''
            });
            fetchBots();
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleDelete = async (bot) => {
        if (!window.confirm(`Are you sure you want to delete ${bot.bot_name}?`)) return;

        try {
            const response = await fetch(`${apiRoot}/bots/delete`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: bot.id })
            });

            if (!response.ok) throw new Error('Failed to delete bot');
            
            toast.success('Bot deleted successfully');
            fetchBots();
        } catch (error) {
            toast.error(error.message);
        }
    };

    return (
        <div className="container mx-auto bg-slate-100 px-4 py-8">
            <div className="mb-6">
                <select
                    className="w-full md:w-64 p-2 border rounded"
                    value={subdaoId || ''}
                    onChange={(e) => setSubdaoId(Number(e.target.value))}
                >
                    <option value="">Select Subdao</option>
                    {communityConfig && Object.entries(communityConfig).map(([id, { name }]) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Bots</h1>
                <button
                    onClick={() => setShowModal(true)}
                    className="bg-cyan-950 hover:bg-cyan-800 text-white px-4 py-2 rounded flex items-center"
                    disabled={!subdaoId}
                >
                    <FiPlus className="mr-2" /> New Bot
                </button>
            </div>

            {isLoading ? (
                <div className="flex justify-center">
                    <FiLoader className="animate-spin h-8 w-8" />
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {bots.map((bot) => (
                        <div key={bot.id} className="border rounded-lg p-4 bg-white shadow">
                            <div className="flex justify-between items-start mb-2">
                                <div className="flex items-center gap-2">
                                    <h3 className="font-bold">{bot.bot_name}</h3>
                                    <span className="px-2 py-0.5 bg-cyan-100 text-cyan-800 text-xs rounded-full">
                                        {bot.model}
                                    </span>
                                </div>
                                <div className="flex space-x-2">
                                    <button
                                        onClick={() => handleEditClick(bot)}
                                        className="text-gray-600 hover:text-gray-800"
                                    >
                                        <FiEdit2 />
                                    </button>
                                    <button
                                        onClick={() => handleDelete(bot)}
                                        className="text-red-600 hover:text-red-800"
                                    >
                                        <FiTrash2 />
                                    </button>
                                </div>
                            </div>
                            <p className="text-sm text-gray-600 mb-4 whitespace-pre-wrap">{bot.prompt}</p>

                            <div className="border-t pt-4">
                                <h4 className="font-semibold mb-2">Documents</h4>
                                <div className="space-y-2">
                                    {botDocuments[bot.id]?.map((doc) => (
                                        <div key={doc} className="flex justify-between items-center">
                                            <span className="text-sm truncate">{doc}</span>
                                            <button
                                                onClick={() => handleDeleteDocument(bot.id, doc)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <FiTrash2 />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-4">
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        multiple
                                        onChange={(e) => handleFileChange(e, bot.id)}
                                        className="hidden"
                                        id={`file-upload-${bot.id}`}
                                    />
                                    <label
                                        htmlFor={`file-upload-${bot.id}`}
                                        className="cursor-pointer inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        <FiUpload className="mr-2" />
                                        Select Files
                                    </label>
                                    {files[bot.id]?.length > 0 && (
                                        <button
                                            onClick={() => handleUpload(bot.id)}
                                            className="ml-2 px-4 py-2 bg-cyan-950 hover:bg-cyan-800 text-white rounded"
                                            disabled={isUploading}
                                        >
                                            {isUploading ? 'Uploading...' : 'Upload'}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 max-w-lg w-full">
                        <h2 className="text-xl font-bold mb-4">
                            {editingBot ? 'Edit Bot' : 'Create New Bot'}
                        </h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Bot Name</label>
                                <input
                                    type="text"
                                    value={formData.bot_name}
                                    onChange={(e) => setFormData({...formData, bot_name: e.target.value})}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Provider</label>
                                <select
                                    value={formData.provider}
                                    onChange={handleProviderChange}
                                    className="w-full p-2 border rounded"
                                    required
                                >
                                    {providers.map(provider => (
                                        <option key={provider.id} value={provider.id}>
                                            {provider.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {providers.find(p => p.id === formData.provider)?.models?.length > 0 && (
                                <div className="mb-4">
                                    <label className="block text-sm font-bold mb-2">Model</label>
                                    <select
                                        value={formData.model}
                                        onChange={(e) => setFormData({...formData, model: e.target.value})}
                                        className="w-full p-2 border rounded"
                                        required
                                    >
                                        {providers.find(p => p.id === formData.provider).models.map(model => (
                                            <option key={model} value={model}>
                                                {model}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className="mb-4">
                                <label className="block text-sm font-bold mb-2">Prompt</label>
                                <textarea
                                    value={formData.prompt}
                                    onChange={(e) => setFormData({...formData, prompt: e.target.value})}
                                    className="w-full p-2 border rounded"
                                    rows="4"
                                    required
                                />
                            </div>
                            <div className="flex justify-end space-x-2">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowModal(false);
                                        setEditingBot(null);
                                        setFormData({
                                            bot_name: '',
                                            prompt: '',
                                            provider: providers.find(p => p.default)?.id || 'claude',
                                            model: providers.find(p => p.default)?.models?.[0] || ''
                                        });
                                    }}
                                    className="px-4 py-2 border rounded"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-cyan-950 hover:bg-cyan-800 text-white rounded"
                                >
                                    {editingBot ? 'Update' : 'Create'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Divebuddies;
