import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useAppContext } from '../AppContext.js';
import ReactMarkdown from 'react-markdown';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

const apiRoot = process.env.REACT_APP_API_URL;

const ChatMessage = ({ entry }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const response = JSON.parse(entry.response);

    return (
        <div className="border rounded-lg p-4 bg-white shadow">
            <p className="font-bold mb-2">Query: {entry.query}</p>
            <div className="text-gray-700">
                <ReactMarkdown className="prose">
                    {response.response}
                </ReactMarkdown>
            </div>
            {response.rag_content && (
                <div className="mt-4">
                    <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="flex items-center text-sm text-gray-600 hover:text-gray-800"
                    >
                        {isExpanded ? <FiChevronDown className="mr-1" /> : <FiChevronRight className="mr-1" />}
                        {isExpanded ? 'Hide Context' : 'Show Context'}
                    </button>
                    {isExpanded && (
                        <div className="mt-2 p-3 bg-gray-50 rounded text-sm text-gray-600 whitespace-pre-wrap">
                            {response.rag_content}
                        </div>
                    )}
                </div>
            )}
            {response.model && (
                <div className="mt-2 text-xs text-gray-500">
                    Model: {response.model}
                </div>
            )}
        </div>
    );
};

const Divebuddyv2 = () => {
    const { communityConfig } = useAppContext();
    const [subdaoId, setSubdaoId] = useState(() => {
        const saved = localStorage.getItem('divebuddy-subdao');
        return saved ? Number(saved) : null;
    });
    const [bots, setBots] = useState([]);
    const [selectedBot, setSelectedBot] = useState(() => {
        const saved = localStorage.getItem('divebuddy-bot');
        return saved ? saved : null;
    });
    const [query, setQuery] = useState('');
    const [responses, setResponses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const jwt = localStorage.getItem("token");

    useEffect(() => {
        // Load chats from localStorage
        const savedChats = localStorage.getItem('chatbot-chats');
        if (savedChats) {
            setResponses(JSON.parse(savedChats));
        }
    }, []);

    useEffect(() => {
        // Save chats to localStorage whenever they change
        localStorage.setItem('chatbot-chats', JSON.stringify(responses));
    }, [responses]);

    useEffect(() => {
        if (subdaoId) {
            fetchBots();
            localStorage.setItem('divebuddy-subdao', subdaoId.toString());
        }
    }, [subdaoId]);

    useEffect(() => {
        if (selectedBot) {
            localStorage.setItem('divebuddy-bot', selectedBot);
        }
    }, [selectedBot]);

    const fetchBots = async () => {
        try {
            const response = await fetch(`${apiRoot}/bots/list?subdao_id=${subdaoId}`, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setBots(data.bots || []);
                
                // If we have a saved bot selection, verify it exists in the new bot list
                const savedBot = localStorage.getItem('divebuddy-bot');
                if (savedBot && !data.bots?.some(bot => bot.id.toString() === savedBot)) {
                    setSelectedBot(null); // Clear selection if bot no longer exists
                    localStorage.removeItem('divebuddy-bot');
                }
            } else {
                toast.error('Failed to fetch bots');
            }
        } catch (error) {
            toast.error('Error fetching bots: ' + error.message);
        }
    };

    const handleSubdaoChange = (event) => {
        const newSubdaoId = Number(event.target.value);
        setSubdaoId(newSubdaoId);
        setSelectedBot(null); // Clear bot selection when subdao changes
        localStorage.removeItem('divebuddy-bot');
    };

    const handleBotSelect = (botId) => {
        setSelectedBot(botId);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedBot || !subdaoId) {
            toast.warning('Please select both a subdao and bot');
            return;
        }

        setIsLoading(true);
        try {
            const chat_history = [
                {
                    role: "user",
                    content: query
                }
            ];

            const response = await fetch(`${apiRoot}/bots/chat?subdao_id=${subdaoId}&bot_id=${selectedBot}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ chat_history })
            });

            if (response.ok) {
                const data = await response.text();
                setResponses(prevResponses => [...prevResponses, { query, response: data }]);
                setQuery(''); // Clear input after successful response
            } else {
                toast.error('Failed to get response from bot');
            }
        } catch (error) {
            toast.error('Error communicating with bot: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container mx-auto bg-slate-100 px-4 py-8">
            <div className="mb-6">
                <select
                    className="w-full md:w-64 p-2 border rounded"
                    value={subdaoId || ''}
                    onChange={handleSubdaoChange}
                >
                    <option value="">Select Subdao</option>
                    {communityConfig && Object.entries(communityConfig).map(([id, { name }]) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-6">
                <select 
                    className="w-full md:w-64 p-2 border rounded"
                    onChange={(e) => handleBotSelect(e.target.value)} 
                    value={selectedBot || ''}
                >
                    <option value="">Select Bot</option>
                    {bots.map(bot => (
                        <option key={bot.id} value={bot.id}>{bot.bot_name}</option>
                    ))}
                </select>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter your query..."
                    disabled={isLoading}
                    className="w-full p-2 border rounded"
                />
                <button 
                    type="submit" 
                    disabled={isLoading || !selectedBot}
                    className="bg-cyan-950 hover:bg-cyan-800 text-white px-4 py-2 rounded"
                >
                    {isLoading ? 'Sending...' : 'Send'}
                </button>
            </form>
            <div className="mt-8 space-y-4">
                {responses.slice().reverse().map((entry, index) => (
                    <ChatMessage key={index} entry={entry} />
                ))}
            </div>
        </div>
    );
};

export default Divebuddyv2;
