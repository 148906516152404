import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, Brush } from 'recharts';
import moment from 'moment';
const apiRoot = process.env.REACT_APP_API_URL;

const HistoryPage = () => {

    const jwt = localStorage.getItem("token");

    const [history, setHistory] = useState([]);
    const [filterReason, setFilterReason] = useState('');
    const [chartData, setChartData] = useState([]);

    const combineTransactions = (transactions) => {
        return transactions.reduce((acc, transaction) => {
            if (transaction.reason.startsWith("access topic") && transaction.send) {
                const existingTransaction = acc.find(t => t.topic_id.Int64 === transaction.topic_id.Int64 && t.send);
                if (existingTransaction) {
                    existingTransaction.amount += transaction.amount;
                    existingTransaction.reason = "access topic"; // Change reason to "access topic"
                    return acc;
                }
            }
            acc.push(transaction);
            return acc;
        }, []);
    };

    /* Load history */
    useEffect(() => {
        const apiUrl = apiRoot + '/payments/history';

        const fetchHistory = async () => {
            if (!jwt) {
                toast.info("You must be logged in to view the history.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setHistory([]);
                return;
            }
            try {
                const response = await fetch(apiUrl, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                });

                if (response.ok) {
                    let data = await response.json();
                    data = combineTransactions(data);
                    data.sort((a, b) => a.created_at.localeCompare(b.created_at));
                    setHistory(data);
                    generateChartData(data);
                } else {
                    const responseBody = await response.text();
                    throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
                }
            } catch (error) {
                toast.error(`Error fetching history: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setHistory([]);
            }
        };

        fetchHistory();
    }, [jwt]);

    const generateChartData = (data) => {
        let cumulativeBalance = 0;
        const chartData = data.map(transaction => {
            cumulativeBalance += transaction.send ? -transaction.amount : transaction.amount;
            return {
                // Format the date as a string in 'YYYY-MM-DD' format for the Brush component
                date: new Date(transaction.created_at).getTime(),
                balance: cumulativeBalance,
                amount: transaction.amount,
                send: transaction.send,
                reason: transaction.reason,
            };
        });

        setChartData(chartData);
    };

    useEffect(() => {
        generateChartData(history);
    }, [history]);

    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' });

    const sortedAndFilteredHistory = useMemo(() => {
        if (!history || history.length === 0) {
            return [];
        }
        let sortableItems = [...history];
        if (filterReason) {
            sortableItems = sortableItems.filter(item => item.reason.includes(filterReason));
        }
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [history, sortConfig, filterReason]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    const handleFilterChange = (event) => {
        setFilterReason(event.target.value);
    }

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className="custom-tooltip bg-white p-2 border border-gray-200 rounded shadow-lg">
                    <p className="label">{`Date: ${moment(data.date).format('LL')}`}</p>
                    <p className="intro">{`Balance: ${data.balance}`}</p>
                    <p className="desc">{`Amount: ${data.send ? '-' : '+'}${data.amount}`}</p>
                    <p className="desc">{`Reason: ${data.reason}`}</p>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="flex flex-col mt-8 items-center justify-center">
            {history.length === 0 ? (
                <div className="text-gray-500 text-lg">No history available.</div>
            ) : (
                <>

                    <div className="w-full max-w-4xl mb-8  rounded-lg">
                        <h2 className="text-2xl font-semibold text-slate-200 mb-4">Balance Over Time</h2>
                        <div className='bg-white rounded-lg py-6 px-4'>
                            <ResponsiveContainer width="100%" height={300}>
                                <AreaChart data={chartData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <defs>
                                        <linearGradient id="colorBalance" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" scale="time" type="number" domain={['dataMin', 'dataMax']} tickFormatter={(unixTime) => moment(unixTime).format('MM/DD/YYYY')} tickCount={6} />
                                    <YAxis dataKey="balance" allowDecimals={false} domain={['auto', 'auto']} />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Area type="monotone" dataKey="balance" stroke="#8884d8" fillOpacity={1} fill="url(#colorBalance)" />
                                    <Brush dataKey="date" height={30} stroke="#8884d8" travellerWidth={10} gap={5} tickFormatter={(unixTime) => moment(unixTime).format('MM/DD/YYYY')} />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                    <div className="w-full max-w-4xl">
                        <h2 className="text-2xl font-semibold text-slate-200 mb-4">Transaction History</h2>
                        <div className="overflow-x-auto">
                            <table className="min-w-full bg-white rounded-lg overflow-hidden">
                                <thead className="bg-gray-800 text-white">
                                    <tr>
                                        <th className="py-3 px-4 text-left cursor-pointer" onClick={() => requestSort('amount')}>Amount</th>
                                        <th className="py-3 px-4 text-left cursor-pointer" onClick={() => requestSort('topic_id')}>Topic</th>
                                        <th className="py-3 px-4 text-left">
                                            <div className="flex justify-between items-center">
                                                <span className="cursor-pointer" onClick={() => requestSort('reason')}>Reason</span>
                                                <select value={filterReason} onChange={handleFilterChange} className="bg-gray-800 text-white rounded border border-gray-700">
                                                    <option value="">All</option>
                                                    {[...new Set(history.map(transaction => transaction.reason))].map((reason, index) => (
                                                        <option key={index} value={reason}>{reason}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </th>
                                        <th className="py-3 px-4 text-left cursor-pointer" onClick={() => requestSort('created_at')}>Date</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700">
                                    {sortedAndFilteredHistory.map((transaction, index) => (
                                        <tr className="border-b" key={index}>
                                            <td className="py-3 px-4 font-bold">
                                                <span className={transaction.send ? 'text-red-500' : 'text-green-500'}>
                                                    {transaction.send ? `- ${transaction.amount.toLocaleString()}` : `+ ${transaction.amount.toLocaleString()}`}
                                                </span>
                                            </td>
                                            <td className="py-3 px-4">
                                                <Link to={`/topic/${transaction.topic_id.Int64}`} className="text-blue-600 hover:text-blue-800 visited:text-purple-600">
                                                    {transaction.topic_id.Int64}
                                                </Link>
                                            </td>
                                            <td className="py-3 px-4">{transaction.reason}</td>
                                            <td className="py-3 px-4">{new Date(transaction.created_at).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export default HistoryPage;

