import { useLocation, useNavigate } from 'react-router-dom';
import { ParseJwt, LoginForm } from '../components/login';
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { CloseButton } from '../components/closeButton';
const apiRoot = process.env.REACT_APP_API_URL;

const ValidatePage = () => {

    const jwt = localStorage.getItem("token");
    const email = localStorage.getItem("email");

    const [emailValidated, setEmailValidated] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    // Initialize countdown from localStorage or default to 5 minutes (300 seconds)
    const [countdown, setCountdown] = useState(parseInt(localStorage.getItem("countdown") || "300"));

    let query = new URLSearchParams(useLocation().search);
    let token = query.get('token');
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            const validateToken = async () => {
                try {
                    const response = await fetch(`${apiRoot}/account/validate?token=${token}`, {
                        method: 'POST',
                    });
                    if (!response.ok) {
                        const responseBody = await response.text();
                        throw new Error(`${responseBody}`);
                    }
                    localStorage.clear();
                    setEmailValidated(true);
                    toast.success('Email verified! You may login now');
                    navigate('/', { replace: true })
                } catch (error) {
                    if (error.message.toLowerCase().includes("email already validated")) {
                        setEmailValidated(true);
                        navigate('/', { replace: true });
                    } else {
                        toast.error(`Token validation error: ${error.message}`);
                    }
                }
            };
            validateToken();
        }
    }, [token, navigate, jwt]);

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prevCountdown) => {
                const newCountdown = prevCountdown - 1;
                if (newCountdown <= 0) {
                    clearInterval(timer);
                    setButtonDisabled(false);
                    localStorage.removeItem("countdown");
                    return 0;
                }
                localStorage.setItem("countdown", newCountdown.toString());
                return newCountdown;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
            localStorage.removeItem("countdown");
        };
    }, []);

    const sendValidationEmail = async () => {
        try {
            const response = await fetch(`${apiRoot}/account/sendvalidationemail?email=${email}`, {
                method: 'POST',
            });
            if (!response.ok) {
                const responseBody = await response.text();
                throw new Error(`${responseBody}`);
            }
            toast.success("Validation email sent successfully.");
            setButtonDisabled(true);
            setCountdown(300); // Reset countdown to 5 minutes
            localStorage.setItem("countdown", "300");
        } catch (error) {
            toast.error(`Error sending validation email: ${error.message}`);
        }
    };

    const handleLoginClick = () => {
        setShowLoginModal(true);
    };

    // Helper function to format countdown in minutes and seconds
    const formatCountdown = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div className="relative items-center flex mt-24 flex-col">
            <div className="rounded-l max-w-md bg-white flex flex-col">
                <div className="flex flex-col text-center p-16 items-center justify-center">
                    {emailValidated ? (
                        <>
                            Your email has been successfully validated, you may login.
                            <button onClick={handleLoginClick} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Login
                            </button>
                        </>
                    ) : (
                        <>
                            To access DiveDeep click below to <break /> send an email verification link to <break /> <b>{email}</b>
                            <button disabled={buttonDisabled} onClick={sendValidationEmail} className={`mt-4 ${buttonDisabled ? 'bg-gray-300' : 'bg-blue-500 hover:bg-blue-700'} text-white font-bold py-2 px-4 rounded`}>
                                {buttonDisabled ? `Please wait ${formatCountdown(countdown)} to resend the verification email if you don't receive it` : 'Send verification email'}
                            </button>
                        </>
                    )}
                </div>
            </div>
            {
                showLoginModal && <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => { setShowLoginModal(false) }}><CloseButton></CloseButton></span>
                        <LoginForm></LoginForm>
                    </div>
                </div>
            }
        </div >
    );
};
export default ValidatePage;
