import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { FaBold, FaItalic, FaListUl, FaListOl, FaLink, FaQuoteLeft, FaCode, FaImage, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

const apiRoot = process.env.REACT_APP_API_URL;

const MarkdownTextBox = ({ 
    initialText = '', 
    onSubmit, 
    onCancel,
    onDelete,
    onChange,
    placeholder = 'Enter your text here...',
    toolbarItems = ['bold', 'italic', 'unordered-list', 'ordered-list', 'link', 'quote', 'image'],
    submitButtonText = 'Submit',
    form = true
}) => {
    const [text, setText] = useState(initialText);
    const [showPreview, setShowPreview] = useState(false);
    const fileInputRef = useRef(null);
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (onChange) {
            onChange(text);
        }
    }, [text, onChange]);

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const insertMarkdown = useCallback((start, end) => {
        const textArea = textAreaRef.current;
        if (!textArea) return;

        const selectionStart = textArea.selectionStart;
        const selectionEnd = textArea.selectionEnd;
        const textBefore = text.substring(0, selectionStart);
        const selectedText = text.substring(selectionStart, selectionEnd);
        const textAfter = text.substring(selectionEnd);

        let newText;
        let newSelectionStart, newSelectionEnd;
        if (selectedText.includes('\n')) {
            // For multiple lines
            const lines = selectedText.split('\n');
            if (start === '- ') {
                newText = lines.map(line => `${start}${line}`).join('\n');
            } else if (start === '1. ') {
                newText = lines.map((line, index) => `${index + 1}. ${line}`).join('\n');
            } else {
                newText = lines.map(line => `${start}${line}${end}`).join('\n');
            }
            newText = `${textBefore}${newText}${textAfter}`;
            newSelectionStart = selectionStart;
            newSelectionEnd = selectionStart + newText.length;
        } else {
            // For single line
            newText = `${textBefore}${start}${selectedText}${end}${textAfter}`;
            newSelectionStart = selectionStart + start.length;
            newSelectionEnd = selectionEnd + start.length + end.length;
        }
        setText(newText);

        // Set cursor position after the inserted markdown
        setTimeout(() => {
            textArea.focus();
            textArea.setSelectionRange(newSelectionStart, newSelectionEnd);
        }, 0);
    }, [text]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch(`${apiRoot}/topic/img`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                insertMarkdown(`![${file.name}](${data.url})`, '');
                toast.success('Image uploaded successfully');
            } else {
                toast.error('Failed to upload image');
            }
        } catch (error) {
            toast.error('An error occurred while uploading the image');
        }
    };

    const toolbarActions = {
        'bold': () => insertMarkdown('**', '**'),
        'italic': () => insertMarkdown('*', '*'),
        'unordered-list': () => insertMarkdown('- ', ''),
        'ordered-list': () => insertMarkdown('1. ', ''),
        'link': () => insertMarkdown('[', '](url)'),
        'quote': () => insertMarkdown('> ', ''),
        'code': () => insertMarkdown('`', '`'),
        'image': () => fileInputRef.current.click()
    };

    const renderToolbarItem = (item) => {
        const icons = {
            'bold': FaBold,
            'italic': FaItalic,
            'unordered-list': FaListUl,
            'ordered-list': FaListOl,
            'link': FaLink,
            'quote': FaQuoteLeft,
            'code': FaCode,
            'image': FaImage
        };
        const Icon = icons[item];
        return (
            <button
                key={item}
                type="button"
                onClick={() => toolbarActions[item]()}
                className={`p-2 text-gray-500 ${showPreview ? 'opacity-50 cursor-not-allowed' : 'hover:text-gray-700'}`}
                disabled={showPreview}
            >
                <Icon />
            </button>
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (showPreview) {
            onSubmit(text);
            setText('');
            setShowPreview(false);
        } else {
            setShowPreview(true);
        }
    };

    const handleCancel = () => {
        if (showPreview) {
            setShowPreview(false);
        } else {
            onCancel();
        }
    };

    const handleDelete = () => {
        onDelete();
        setText('');
    };

    const togglePreview = () => {
        setShowPreview(!showPreview);
    };

    return (
        <div className="w-full mx-auto">
            <div className="mb-2 flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="flex flex-wrap gap-2 mb-2 sm:mb-0">
                    {toolbarItems.map(renderToolbarItem)}
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleImageUpload}
                        accept="image/*"
                        className="hidden"
                        disabled={showPreview}
                    />
                </div>
            </div>
            {form ? (
                <form onSubmit={handleSubmit}>
                    {showPreview ? (
                        <div className="border p-4 rounded-md min-h-[200px] prose max-w-full">
                            <ReactMarkdown
                                components={{
                                    img: ({node, ...props}) => (
                                        <img style={{maxHeight: '312px'}} {...props} />
                                    ),
                                }}
                            >
                                {text}
                            </ReactMarkdown>
                        </div>
                    ) : (
                        <textarea
                            id="markdown-textarea"
                            ref={textAreaRef}
                            value={text}
                            onChange={handleTextChange}
                            placeholder={placeholder}
                            className="w-full p-2 border rounded-md min-h-[200px]"
                        />
                    )}
                    <div className="mt-2 flex justify-end gap-2">
                        {onDelete && (
                            <button
                                type="button"
                                onClick={handleDelete}
                                className="px-4 py-2 bg-red-800 text-white rounded hover:bg-red-700 flex items-center"
                            >
                                <FaTrash className="mr-2" />
                                Delete
                            </button>
                        )}
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                        >
                            {showPreview ? 'Edit' : 'Cancel'}
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-cyan-900 text-white rounded hover:bg-cyan-800"
                        >
                            {showPreview ? submitButtonText : 'Preview'}
                        </button>
                    </div>
                </form>
            ) : (
                <>
                    {showPreview ? (
                        <div className="border p-4 rounded-md min-h-[200px] prose max-w-full">
                            <ReactMarkdown
                                components={{
                                    img: ({node, ...props}) => (
                                        <img style={{maxHeight: '312px'}} {...props} />
                                    ),
                                }}
                            >
                                {text}
                            </ReactMarkdown>
                        </div>
                    ) : (
                        <textarea
                            id="markdown-textarea"
                            ref={textAreaRef}
                            value={text}
                            onChange={handleTextChange}
                            placeholder={placeholder}
                            className="w-full p-2 border rounded-md min-h-[200px]"
                        />
                    )}
                    <div className="mt-2 flex justify-end">
                        <button
                            type="button"
                            onClick={togglePreview}
                            className="px-4 py-2 bg-cyan-900 text-white rounded hover:bg-cyan-800"
                        >
                            {showPreview ? 'Edit' : 'Preview'}
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default MarkdownTextBox;
