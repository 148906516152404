import * as React from "react";
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useAppContext } from '../AppContext';
import CreateAccount from "./createAccount";
import Select from 'react-select'
import { useRemark } from 'react-remark';
import { FaPlus, FaCoins } from 'react-icons/fa';
import MarkdownTextBox from "./textBox";
import ProfilePicture from "./ProfilePicture";

const apiRoot = process.env.REACT_APP_API_URL;
const jwt = localStorage.getItem("token");

const CreateTopicModal = () => {
    const { communityConfig } = useAppContext();
    const [selectedCommunity, setSelectedCommunity] = useState();
    const [showQuestionForm, setShowQuestionForm] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const [isCreateAccountModalOpen, setCreateAccountModalOpen] = useState(false);
    const openCreateAccountModal = () => {
        setCreateAccountModalOpen(true);
        window.history.pushState(null, '', window.location.pathname);
    };
    const closeCreateAccountModal = () => setCreateAccountModalOpen(false);

    const [questionTitle, setQuestionTitle] = useState('');
    const [questionBody, setQuestionBody] = useState('');
    const [showTipSection, setShowTipSection] = useState(false);
    const [questionMinResponders, setQuestionMinResponders] = useState(1);
    const [questionBounty, setQuestionBounty] = useState(0.0);
    const [questionAnonymous, setQuestionAnonymous] = useState(true);
    const { balance } = useAppContext();
    const topicCreateCost = selectedCommunity ? communityConfig[selectedCommunity].topic_create_cost : 0;
    const maxBounty = Math.floor(balance - topicCreateCost); // Cap for bounty

    const totalExperts = Object.values(communityConfig).reduce((acc, { expert_count }) => acc + expert_count, 0);

    const [showImageInput, setShowImageInput] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [reactContent, setMarkdownSource] = useRemark();

    useEffect(() => {
        setMarkdownSource(questionBody);
    }, [questionBody, setMarkdownSource]);

    useEffect(() => {
        if (communityConfig && Object.keys(communityConfig).length === 1) {
            setSelectedCommunity(Object.keys(communityConfig)[0]);
        }
    }, [communityConfig]);

    useEffect(() => {
        const handlePopState = () => {
            if (isCreateAccountModalOpen) {
                closeCreateAccountModal();
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [isCreateAccountModalOpen]);

    const [specialists, setSpecialists] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchSpecialists = async () => {
            if (!selectedCommunity) return;
            setIsLoading(true);
            try {
                const response = await fetch(`${apiRoot}/communities/specialists?subdao_id=${selectedCommunity}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch specialists');
                }
                const data = await response.json();
                setSpecialists(data);
            } catch (error) {
                console.error('Error fetching specialists:', error);
                setSpecialists([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchSpecialists();
    }, [selectedCommunity]);

    const SpecialistCircles = () => {
        if (selectedCommunity === '2') {
            return null;
        }

        if (!isLoading) {
            return (
                <div className="flex flex-wrap justify-center gap-4 mb-6">
                    {specialists.map((specialist, index) => (
                        <div key={index} className="flex flex-col items-center">
                            <div className="rounded-full w-14 h-14 overflow-hidden shrink-0">
                                <ProfilePicture
                                    url={specialist.profile_picture_url}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="text-xs mt-1 text-center w-20 text-zinc-600 truncate">
                                {specialist.name || 'Anonymous'}
                            </div>
                        </div>
                    ))}
                    <div className="flex flex-col items-center">
                        <div className="w-14 h-14 rounded-full bg-zinc-300 flex items-center justify-center text-white font-bold">
                            <FaPlus />
                        </div>
                        <div className="text-xs mt-1 text-center w-20 text-zinc-400 truncate">
                            and more
                        </div>
                    </div>
                </div>
            );
        }

        const placeholderSpecialists = Array(5).fill({
            profile_picture_url: '',
            name: 'Loading...'
        });

        return (
            <div className="flex flex-wrap justify-center gap-4 mb-6">
                {placeholderSpecialists.map((specialist, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <div className="rounded-full w-14 h-14 overflow-hidden shrink-0 bg-gray-200 animate-pulse">
                        </div>
                        <div className="text-xs mt-1 text-center w-20 text-zinc-400 truncate">
                            {specialist.name}
                        </div>
                    </div>
                ))}
                <div className="flex flex-col items-center">
                    <div className="w-14 h-14 rounded-full bg-zinc-300 flex items-center justify-center text-white font-bold">
                        <FaPlus />
                    </div>
                    <div className="text-xs mt-1 text-center w-20 text-zinc-400 truncate">
                        and more
                    </div>
                </div>
            </div>
        );
    };
    const handleAddImage = () => {
        if (imageUrl) {
            const imageMarkdown = `![Image](${imageUrl})`;
            const newQuestionBody = questionBody + '\n' + imageMarkdown;
            setQuestionBody(newQuestionBody);
            setMarkdownSource(newQuestionBody);
            setImageUrl('');
            setShowImageInput(false);
        }
    };
    const handleCancelAddImage = () => {
        setImageUrl('');
        setShowImageInput(false);
    };
    const handleQuestionSubmit = async (e) => {
        const jwt = localStorage.getItem("token");
        e.preventDefault();
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
            const jsonQuestion = {
                title: questionTitle,
                body: questionBody,
                min_response: questionMinResponders,
                bounty: questionBounty,
                subdao_id: typeof selectedCommunity === 'string' ? parseInt(selectedCommunity, 10) : selectedCommunity,
                anonymous: questionAnonymous,
            }
            const response = await fetch(apiRoot + '/topic', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
                body: JSON.stringify(jsonQuestion),
            });
            if (!response.ok) {
                const responseBody = await response.text();
                throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
            }
            // If the post was successful, navigate to the new topic page
            const data = await response.json();
            window.location.href = `/topic/${data.topic_id}`;
        } catch (error) {
            toast.error(`Error asking question: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="items-stretch bg-white flex sm:w-[36rem] flex-col justify-center py-6 rounded-2xl">
            {!isCreateAccountModalOpen && (
                <>
                    <div className="mb-6 text-black text-center text-2xl leading-9 max-md:max-w-full">
                        {selectedCommunity ? (communityConfig[selectedCommunity].expert_auth_required ? <span>Ask all <b>{communityConfig[selectedCommunity].expert_count}</b> specialists in the</span> : <span>Ask <b>anyone</b> in the</span>) : <span>Ask <b>{totalExperts}</b> specialists across the platform</span>}
                        {Object.keys(communityConfig).length > 1 ? (
                            <Select
                                className="text-cyan-700 font-extrabold bg-transparent border-none cursor-pointer outline-none transition-colors p-1 duration-500 ease-in-out hover:bg-cyan-700 hover:bg-opacity-10 rounded-xl ml-2"
                                value={selectedCommunity ? { value: selectedCommunity, label: communityConfig[selectedCommunity].name } : ''}
                                onChange={(option) => setSelectedCommunity(option.value)}
                                options={communityConfig ? Object.keys(communityConfig).map((id) => ({ value: id, label: communityConfig[id].name })) : []}
                                placeholder="Select a community"
                                menuPortalTarget={document.body}
                                blurInputOnSelect={true}
                                isSearchable={false}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                                }}
                            />
                        ) : (
                            <div className="text-cyan-700 font-extrabold ml-2">
                                {communityConfig[Object.keys(communityConfig)[0]].name}
                            </div>
                        )}
                        {selectedCommunity && `Community`}
                    </div>
                    <div className="mb-6 text-black text-center">
                        {communityConfig && selectedCommunity && (
                            <>
                                <SpecialistCircles />
                                <div className="flex-row bg-slate-100 justify-center p-4 rounded-lg shadow">
                                    <div className="italic text-gray-700">
                                        {JSON.parse(communityConfig[selectedCommunity].metadata).description}
                                    </div>
                                </div>
                                <div className="mt-6 text-gray-800">
                                        The specialists have these traits:
                                    </div>
                                    <div className="flex flex-wrap justify-center gap-2 mt-2">
                                        {JSON.parse(communityConfig[selectedCommunity].metadata).traits.map((trait, index) => (
                                            <span key={index} className="px-3 py-1 bg-slate-200 text-cyan-800 rounded-full border-2 border-cyan-700">
                                                <b>{trait}</b>
                                            </span>
                                        ))}
                                    </div>
                            </>
                        )}
                    </div>
                    {!showQuestionForm && jwt && selectedCommunity && (
                        <button onClick={() => setShowQuestionForm(true)} className="w-full px-4 py-6 bg-cyan-950 text-white rounded-lg hover:bg-cyan-800 focus:outline-none">
                            Continue with the {communityConfig[selectedCommunity].name} community
                        </button>
                    )}
                </>
            )}
            {showQuestionForm && (
                <form onSubmit={handleQuestionSubmit} className="space-y-4 mt-8">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="question" className="block text-xl font-bold text-gray-700">Title</label>
                            <input type="text" id="question" name="question" placeholder="Type your title here" autoComplete="off" onChange={(e) => setQuestionTitle(e.target.value)} className="border border-gray-300 border-solid rounded-lg p-2 mt-1 block w-full shadow-sm" />
                        </div>
                        <div>
                            <label htmlFor="context" className="block text-xl font-bold mb-3 text-gray-700">Additional context</label>
                            <MarkdownTextBox
                                form={false}
                                initialText={questionBody}
                                onChange={(text) => setQuestionBody(text)}
                                onSubmit={(text) => {
                                    setQuestionBody(text);
                                    setMarkdownSource(text);
                                }}
                                placeholder="Type your context here"
                                toolbarItems={['bold', 'italic', 'unordered-list', 'ordered-list', 'link', 'quote', 'image']}
                                submitButtonText="Update"
                            />
                        </div>
                    </div>
                    <div className="h-4"/>
                    <div className="flex flex-col items-center w-full text-center">
                        <button
                            type="button"
                            onClick={() => setShowTipSection(!showTipSection)}
                            className="w-full flex items-center justify-center text-xl font-bold text-white bg-blue-500 hover:bg-cyan-800 transition-colors duration-200 py-4 px-4 rounded"
                        >
                            <FaCoins className="mr-2" />
                            Add a tip
                        </button>
                        <p className="text-sm text-gray-600 mt-2">Adding a tip incentivizes specialists to answer quicker</p>
                    </div>
                    {showTipSection && (
                        <div className="items-stretch border border-[color:var(--Neutrals-100,#E0E0E0)] bg-slate-100 flex flex-col mt-2 p-4 rounded-lg border-solid max-md:max-w-full">
                            <div className="grid grid-flow-row flex-wrap justify-stretch items-center rounded-xl p-2 gap-6 sm:p-10">
                                <div className="w-full">
                                    <label htmlFor="responders" className="block text-md font-medium text-gray-700">
                                        Minimum responders:
                                    </label>
                                    <input type="range" id="responders" name="responders" min="1" max="10" value={questionMinResponders} onChange={(e) => setQuestionMinResponders(parseInt(e.target.value, 10))} className="mt-1 w-full" />
                                    <input type="number" id="respondersInput" name="respondersInput" min="1" value={questionMinResponders} onChange={(e) => setQuestionMinResponders(parseInt(e.target.value, 10))} className="m-auto p-4 w-full mt-4 block text-center border-gray-300 rounded-md shadow-sm" />
                                </div>
                                <div className="w-full">
                                    <label htmlFor="bounty" className="block text-md font-medium text-gray-700">
                                        Tip:
                                    </label>
                                    <input type="range" id="bounty" name="bounty" min="0" max={100} step={questionMinResponders} value={questionBounty} onChange={(e) => setQuestionBounty(Math.min(parseFloat(e.target.value), maxBounty))} className="mt-1 w-full" />
                                    <input type="number" id="bountyInput" name="bountyInput" min="0" max={maxBounty} step={questionMinResponders} value={questionBounty} onChange={(e) => setQuestionBounty(Math.min(parseFloat(e.target.value), maxBounty))} className="m-auto text-center p-4 w-full mt-4 block border-gray-300 rounded-md shadow-sm" />
                                </div>
                                <div className="text-center sm:text-right w-full">
                                    The first <b>{questionMinResponders === 1 ? (<span>expert</span>) : (<span>{questionMinResponders} experts </span>)}</b> to answer <br></br> will get <b>{((questionBounty + topicCreateCost) / questionMinResponders)}</b> creds {questionMinResponders === 1 ? (<span></span>) : (<span>each</span>)}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="h-4"/>
                    <div className="flex flex-col sm:flex-row justify-center items-center p-4 bg-slate-100 rounded-lg gap-4">
                        <div className="flex items-center gap-2">
                            <div className="rounded-full object-contain object-center w-8 h-8 justify-center items-center overflow-hidden shrink-0 max-w-full">
                                <ProfilePicture
                                    url={questionAnonymous ? null : localStorage.getItem("profile_picture_url")}
                                />
                            </div>
                            <div className={`text-sm leading-5 ${questionAnonymous ? 'italic text-zinc-500' : 'text-zinc-800 font-medium'}`}>
                                {questionAnonymous ? "Anonymous" : localStorage.getItem("name")}
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span className="mr-2 text-gray-700">Ask anonymously</span>
                            <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={questionAnonymous}
                                    onChange={() => setQuestionAnonymous(!questionAnonymous)}
                                    className="sr-only peer"
                                />
                                <div className="w-11 h-6 bg-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-cyan-950 outline-none focus:outline-none hover:outline-none"></div>
                            </label>
                        </div>
                    </div>
                    <button 
                        type="submit" 
                        className={`w-full h-32 px-4 py-2 text-white rounded-lg focus:outline-none ${isSubmitting ? 'bg-gray-500 cursor-not-allowed' : 'bg-cyan-950 hover:bg-cyan-800'}`}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <span className="text-xl">Submitting...</span>
                        ) : (
                            <>
                                <b className='text-xl'>Submit question for {questionBounty + topicCreateCost} creds </b><br></br> 
                                {topicCreateCost} cred ask fee + {questionBounty} cred tip
                            </>
                        )}
                    </button>
                </form>
            )}
            {!jwt && !isCreateAccountModalOpen && (
                <button onClick={openCreateAccountModal} className="w-full px-4 py-6 bg-cyan-950 text-white rounded-lg hover:bg-cyan-800 focus:outline-none">
                    Create an account to ask a question
                </button>
            )}
            {isCreateAccountModalOpen && (
                <div className="flex justify-center items-center">
                    <CreateAccount></CreateAccount>
                </div>
            )}
        </div>
    );
};

export default CreateTopicModal;
