import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import ProfilePicture from '../components/ProfilePicture';
import { useAppContext } from '../AppContext';
import { MdEmail } from "react-icons/md";

const apiRoot = process.env.REACT_APP_API_URL;

const ProfilePage = () => {
    const [jwt, setJwt] = useState(localStorage.getItem("token"));
    const [profilePicture, setProfilePicture] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const { communityConfig } = useAppContext();
    const fileInputRef = useRef(null);
    const [accountInfo, setAccountInfo] = useState(null);
    const [activeTab, setActiveTab] = useState('account');

    const location = useLocation();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (token) {
            const decodedToken = decodeURIComponent(atob(token).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            setJwt(decodedToken);
        }
    }, [location]);

    useEffect(() => {
        const fetchAccountInfo = async () => {
            try {
                const response = await fetch(`${apiRoot}/account`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setAccountInfo(data);
                    if (data.profilePictureUrl) {
                        localStorage.setItem('profile_picture_url', data.profilePictureUrl);
                    }
                } else {
                    const error = await response.json();
                    toast.error(error.message || "Failed to fetch account information");
                }
            } catch (error) {
                toast.error("An error occurred while fetching account information");
            }
        };

        if (jwt) {
            fetchAccountInfo();
        }
    }, [jwt]);



    const specialistCommunities = accountInfo?.subdaoAuths
        .filter(auth => auth.auth_level >= 1)
        .map(auth => {
            const community = communityConfig[auth.subdao_id];
            if (community) {
                return {
                    ...community,
                    specialties: auth.specialties,
                    subdao_id: auth.subdao_id
                };
            }
            return null;
        })
        .filter(Boolean);

    const tabs = [
        { id: 'account', label: 'Account' },
        { id: 'communities', label: 'Communities' },
        { id: 'metrics', label: 'Metrics' },
    ];
    const UploadTab = () => {
        const [selectedFile, setSelectedFile] = useState(null);
        const [specialty, setSpecialty] = useState('');

        const handleFileSelect = (e) => {
            setSelectedFile(e.target.files[0]);
        };

        const handleSpecialtyChange = (e) => {
            setSpecialty(e.target.value);
        };

        const handleUpload = async (e) => {
            e.preventDefault();

            if (!selectedFile) {
                toast.error("Please select a PDF file");
                return;
            }

            const formData = new FormData();
            formData.append('pdf', selectedFile);
            formData.append('specialty', specialty);

            try {
                const response = await fetch(`${apiRoot}/documents/medical/upload`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    },
                    body: formData
                });

                if (response.ok) {
                    const data = await response.json();
                    toast.success(data.message || "Document uploaded successfully");
                    setSelectedFile(null);
                    setSpecialty('');
                } else {
                    const error = await response.json();
                    if (error.message.includes("malformed PDF")) {
                        toast.error("The uploaded PDF appears to be corrupted or invalid. Please check the file and try again.");
                    } else {
                        toast.error(error.message || "Failed to upload document");
                    }
                }
            } catch (error) {
                toast.error("An error occurred while uploading the document");
            }
        };
        return (
            <div className="mt-4">
                <h2 className="text-xl font-semibold mb-4">Upload Medical Document</h2>
                <form onSubmit={handleUpload}>
                    <div className="mb-4">
                        <label htmlFor="file" className="block text-sm font-medium text-gray-700">
                            Select PDF File
                        </label>
                        <input
                            type="file"
                            id="file"
                            accept=".pdf"
                            onChange={handleFileSelect}
                            className="mt-1 block w-full text-sm text-gray-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-blue-50 file:text-blue-700
                            hover:file:bg-blue-100"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="specialty" className="block text-sm font-medium text-gray-700">
                            Specialty
                        </label>
                        <input
                            type="text"
                            id="specialty"
                            value={specialty}
                            onChange={handleSpecialtyChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                    </div>
                    <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Upload Document
                    </button>
                </form>
            </div>
        );
    };

    //tabs.push({ id: 'upload', label: 'Upload Document' });

    const isAdmin = localStorage.getItem("is_admin") === "true";

    const AdminTab = () => {
        const [email, setEmail] = useState('');
        const [errorMessage, setErrorMessage] = useState('');
        const [specialists, setSpecialists] = useState([]);
        const [selectedCommunityId, setSelectedCommunityId] = useState('');
        const [communities, setCommunities] = useState([]);

        useEffect(() => {
            if (communityConfig) {
                const communityList = Object.values(communityConfig).filter(community => community.expert_auth_required);
                setCommunities(communityList);
                if (communityList.length > 0) {
                    setSelectedCommunityId(communityList[0].id.toString());
                }
            }
            fetchSpecialists();
        }, []);

        useEffect(() => {
            if (selectedCommunityId) {
                fetchSpecialists();
            }
        }, [selectedCommunityId]);

        const fetchSpecialists = async () => {
            if (!selectedCommunityId) return;
            try {
                const response = await fetch(`${apiRoot}/account/specialist?community_id=${selectedCommunityId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setSpecialists(data.specialists);
                } else {
                    toast.error("Failed to fetch specialists");
                }
            } catch (error) {
                toast.error("An error occurred while fetching specialists");
            }
        };

        const handleEmailChange = (e) => {
            setEmail(e.target.value);
            setErrorMessage('');
        };

        const handleAddSpecialist = async (e) => {
            e.preventDefault();
            setErrorMessage('');

            try {
                const response = await fetch(`${apiRoot}/account/specialist?email=${encodeURIComponent(email)}&community_id=${selectedCommunityId}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });

                if (response.ok) {
                    toast.success("Specialist added successfully");
                    setEmail('');
                    fetchSpecialists();
                } else if (response.status === 404) {
                    setErrorMessage('Email not found');
                } else {
                    const error = await response.json();
                    toast.error(error.message || "Failed to add specialist");
                }
            } catch (error) {
                toast.error("An error occurred while adding specialist");
            }
        };

        const handleDeleteSpecialist = async (specialistEmail) => {
            try {
                const response = await fetch(`${apiRoot}/account/specialist?email=${encodeURIComponent(specialistEmail)}&community_id=${selectedCommunityId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    }
                });

                if (response.ok) {
                    toast.success("Specialist removed successfully");
                    fetchSpecialists();
                } else {
                    const error = await response.json();
                    toast.error(error.message || "Failed to remove specialist");
                }
            } catch (error) {
                toast.error("An error occurred while removing specialist");
            }
        };

        return (
            <div className="max-w-4xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
                {communities.length > 0 && (
                    <div className="mb-6">
                        <label htmlFor="communitySelect" className="block text-sm font-medium text-gray-700 mb-2">
                            Select Community
                        </label>
                        <select
                            id="communitySelect"
                            value={selectedCommunityId}
                            onChange={(e) => setSelectedCommunityId(e.target.value)}
                            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500"
                        >
                            {communities.map((community) => (
                                <option key={community.id} value={community.id.toString()}>
                                    {community.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
                <h2 className="text-2xl font-bold mb-6 text-gray-800">Add Specialist</h2>
                <form onSubmit={handleAddSpecialist} className="mb-8">
                    <div className="mb-4">
                        <label htmlFor="specialistEmail" className="block text-sm font-medium text-gray-700 mb-2">
                            Specialist Email
                        </label>
                        <div className="relative">
                            <input
                                type="email"
                                id="specialistEmail"
                                value={email}
                                onChange={handleEmailChange}
                                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                required
                                placeholder="Enter specialist's email"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                                <MdEmail className="h-5 w-5 text-gray-400" />
                            </div>
                        </div>
                        {errorMessage && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg transition duration-150 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Add Specialist
                    </button>
                </form>

                <h2 className="text-2xl font-bold mb-4 text-gray-800">Current Specialists</h2>
                <ul className="divide-y divide-gray-200">
                    {specialists.map((specialist) => (
                        <li key={specialist} className="py-4 flex justify-between items-center">
                            <span className="select-text text-gray-700">{specialist}</span>
                            <button
                                onClick={() => handleDeleteSpecialist(specialist)}
                                className="ml-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-full text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                Remove
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };
    if (isAdmin) {
        tabs.push({ id: 'admin', label: 'Admin' });
    }

    const AccountTab = () => {
        const handleFileChange = (e) => {
            setProfilePicture(e.target.files[0]);
        };

        const handleProfilePictureSubmit = async (e) => {
            e.preventDefault();

            if (!profilePicture) {
                toast.error("Please select a profile picture");
                return;
            }

            const formData = new FormData();
            formData.append('image', profilePicture);

            try {
                const response = await fetch(`${apiRoot}/account/profile/upload`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${jwt}`
                    },
                    body: formData
                });

                if (response.ok) {
                    const data = await response.json();
                    setAccountInfo(prevState => ({
                        ...prevState,
                        profilePictureUrl: data.url
                    }));
                    localStorage.setItem('profile_picture_url', data.url);
                    toast.success("Profile picture uploaded successfully");
                    setProfilePicture(null);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                } else {
                    const error = await response.json();
                    toast.error(error.message || "Failed to upload profile picture");
                }
            } catch (error) {
                toast.error("An error occurred while uploading profile picture");
            }
        };

        const handlePasswordSubmit = async (e) => {
            e.preventDefault();

            if (newPassword !== confirmPassword) {
                toast.error("New password and confirm password do not match");
                return;
            }

            try {
                const response = await fetch(`${apiRoot}/account`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    },
                    body: JSON.stringify({ "password": newPassword })
                });

                if (response.ok) {
                    toast.success("Password changed successfully");
                    setNewPassword('');
                    setConfirmPassword('');
                } else {
                    const error = await response.json();
                    toast.error(error.message);
                }
            } catch (error) {
                toast.error("An error occurred while changing password");
            }
        };

        return (
            <div className='flex flex-col items-center gap-8'>
                <div className="flex justify-center items-center gap-8">
                    <div className="flex flex-col items-center">
                        <div className="aspect-square object-contain object-center w-40 overflow-hidden shrink-0">
                            <ProfilePicture pseudoanon={false} url={accountInfo.profilePictureUrl} />
                        </div>
                        <p className="mt-2 text-center font-medium">Specialist</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="aspect-square object-contain object-center w-40 overflow-hidden shrink-0">
                            <ProfilePicture userAddressId={accountInfo.subdaoAuths[0]?.address_id} />
                        </div>
                        <p className="mt-2 text-center font-medium">Pseudoanonymous</p>
                    </div>
                </div>
                <div className="w-64 text-center p-4 bg-slate-200 rounded-lg shadow-sm">
                    <p className="text-sm text-gray-700">
                        Your <b>specialist</b> profile picture will appear when creating or responding to topics or commenting on responses in a community where you are a specialist. <br /> <br />
                        Your <b>pseudoanonymous</b> profile picture will appear when creating a topic or commenting on a response in a community you aren't a specialist in.
                    </p>
                </div>
                <form onSubmit={handleProfilePictureSubmit} className="space-y-4">
                    <div className="relative">
                        <input
                            type="file"
                            id="profilePicture"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="hidden"
                            ref={fileInputRef}
                        />
                        {profilePicture && (
                            <p className="mt-2 text-sm text-center mb-4 text-gray-600">Selected: {profilePicture.name}</p>
                        )}
                        {profilePicture ? (
                            <div className="flex space-x-2">
                                <label
                                    htmlFor="profilePicture"
                                    className="flex-1 py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer inline-block text-center"
                                >
                                    Choose Another
                                </label>
                                <button
                                    type="submit"
                                    className="flex-1 py-2 px-4 bg-green-500 text-white font-bold rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 cursor-pointer"
                                >
                                    Upload
                                </button>
                            </div>
                        ) : (
                            <label
                                htmlFor="profilePicture"
                                className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 cursor-pointer inline-block text-center"
                            >
                                Upload Profile Picture
                            </label>
                        )}
                    </div>
                </form>
                <div className='flex flex-col items-center gap-8 w-full max-w-md'>
                    <h2 className="text-2xl font-bold">Change Password</h2>
                    <form onSubmit={handlePasswordSubmit} className="space-y-4 w-full">
                        <div>
                            <label htmlFor="newPassword" className="block text-gray-700 font-bold mb-2">New Password:</label>
                            <input
                                type="password"
                                id="newPassword"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <div>
                            <label htmlFor="confirmPassword" className="block text-gray-700 font-bold mb-2">Confirm Password:</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                        <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Change Password</button>
                    </form>
                </div>
            </div>
        )
    };

    const CommunityTab = () => {
        const [newSpecialties, setNewSpecialties] = useState({});
        const [editingSpecialty, setEditingSpecialty] = useState(false);

        const handleAddSpecialty = async (subdaoId) => {
            const newSpecialty = newSpecialties[subdaoId];
            if (!newSpecialty || !newSpecialty.trim()) {
                toast.error("Please enter a specialty");
                return;
            }

            try {
                const response = await fetch(`${apiRoot}/account/specialties?subdao_id=${subdaoId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    },
                    body: JSON.stringify({ "specialties": [newSpecialty] })
                });

                if (response.ok) {
                    const updatedAccountInfo = { ...accountInfo };
                    const subdaoAuth = updatedAccountInfo.subdaoAuths.find(auth => auth.subdao_id === subdaoId);
                    if (subdaoAuth) {
                        subdaoAuth.specialties = [...subdaoAuth.specialties, newSpecialty];
                    }
                    setAccountInfo(updatedAccountInfo);
                    setNewSpecialties(prev => ({ ...prev, [subdaoId]: '' }));
                    setEditingSpecialty(null);
                    toast.success("Specialty added successfully");
                } else {
                    const error = await response.json();
                    toast.error(error.message || "Failed to add specialty");
                }
            } catch (error) {
                toast.error("An error occurred while adding specialty");
            }
        };

        const handleDeleteSpecialty = async (subdaoId, specialty) => {
            try {
                const response = await fetch(`${apiRoot}/account/specialties?subdao_id=${subdaoId}`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`
                    },
                    body: JSON.stringify({ "specialties": [specialty] })
                });

                if (response.ok) {
                    const updatedAccountInfo = { ...accountInfo };
                    const subdaoAuth = updatedAccountInfo.subdaoAuths.find(auth => auth.subdao_id === subdaoId);
                    if (subdaoAuth) {
                        subdaoAuth.specialties = subdaoAuth.specialties.filter(s => s !== specialty);
                    }
                    setAccountInfo(updatedAccountInfo);
                    toast.success("Specialty deleted successfully");
                } else {
                    const error = await response.json();
                    toast.error(error.message || "Failed to delete specialty");
                }
            } catch (error) {
                toast.error("An error occurred while deleting specialty");
            }
        };

        return (
            <div className='flex flex-col items-center gap-2'>
                <h2 className="text-xl font-bold">You are a specialist in</h2>
                <div className="flex flex-wrap justify-center gap-4">
                    {specialistCommunities.map((community) => {
                        const baseHue = community.name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0) % 300;
                        const hashColor = `hsla(${baseHue}, 20%, 50%, 1)`;

                        return (
                            <div key={community.id} className="flex flex-col items-center p-6 bg-white rounded-lg shadow-md w-72">
                                <div
                                    className="text-white select-none text-center text-lg font-bold leading-6 whitespace-nowrap px-4 py-2 rounded-full mb-5 w-full"
                                    style={{ backgroundColor: hashColor }}
                                >
                                    {community.name}
                                </div>
                                <div className="flex flex-wrap gap-3 mb-3">
                                    {community.specialties.map((specialty, index) => (
                                        <div key={index} className="flex items-center bg-gray-200 rounded-full px-4 py-2">
                                            <span className="text-base">{specialty}</span>
                                            <button
                                                onClick={() => handleDeleteSpecialty(community.subdao_id, specialty)}
                                                className="ml-2 text-red-500 hover:text-red-700 focus:outline-none"
                                            >
                                                ×
                                            </button>
                                        </div>
                                    ))}
                                    {editingSpecialty === community.subdao_id ? (
                                        <div className="flex items-center bg-blue-100 rounded-full px-4 py-2">
                                            <input
                                                type="text"
                                                value={newSpecialties[community.subdao_id] || ''}
                                                onChange={(e) => setNewSpecialties(prev => ({ ...prev, [community.subdao_id]: e.target.value }))}
                                                placeholder="New specialty"
                                                className="bg-transparent border-none focus:outline-none text-base"
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleAddSpecialty(community.subdao_id);
                                                    }
                                                }}
                                            />
                                            <button
                                                onClick={() => handleAddSpecialty(community.subdao_id)}
                                                className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                                            >
                                                +
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => setEditingSpecialty(community.subdao_id)}
                                            className="bg-gray-200 rounded-full px-4 py-2 text-base hover:bg-gray-300 focus:outline-none"
                                        >
                                            <span className="font-bold">+</span> <span className="text-sm text-gray-600">Specialty</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }

    const MetricsTab = () => {
        return (
            <div className='flex flex-col items-center gap-8'>
                <h2 className="text-2xl font-bold">Account Metrics</h2>
                <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-2xl">
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <p className="font-semibold">Topics Created:</p>
                            <p>{accountInfo.topicsCreated}</p>
                        </div>
                        <div>
                            <p className="font-semibold">Responses Created:</p>
                            <p>{accountInfo.responsesCreated}</p>
                        </div>
                        <div>
                            <p className="font-semibold">Endorsements Received:</p>
                            <p>{accountInfo.endorsementsReceived}</p>
                        </div>
                        <div>
                            <p className="font-semibold">Flares Received:</p>
                            <p>{accountInfo.flaresReceived}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className="flex flex-col md:flex-row select-none bg-slate-100 w-full mx-auto">
            <div className="md:w-64 mb-4 md:mb-0">
                <ul className="flex md:flex-col bg-white shadow overflow-hidden">
                    {tabs.map((tab) => (
                        <li key={tab.id} className="flex-1 md:flex-none">
                            <button
                                onClick={() => setActiveTab(tab.id)}
                                className={`w-full text-center md:text-left px-6 py-4 text-sm font-medium ${activeTab === tab.id
                                    ? 'bg-blue-500 text-white'
                                    : 'text-gray-700 hover:bg-gray-100'
                                    } transition-colors duration-200 focus:outline-none`}
                            >
                                {tab.label}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex-1 md:ml-8">
                {accountInfo && (
                    <div className='bg-white rounded-lg shadow-md p-6'>
                        {activeTab === 'account' && <AccountTab />}
                        {activeTab === 'communities' && <CommunityTab />}
                        {activeTab === 'metrics' && <MetricsTab />}
                        {activeTab === 'admin' && <AdminTab />}
                        {activeTab === 'upload' && <UploadTab />}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProfilePage;
