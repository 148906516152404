import * as React from "react";
import ProfilePicture from "./ProfilePicture";
import TimeSince from "./timeSince";
import { useState, useEffect } from 'react';
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { FaArrowUp, FaStar, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Tooltip, TooltipProvider } from "react-tooltip";
import ReactMarkdown from 'react-markdown';
import MarkdownTextBox from "./textBox";

const expertAuth = process.env.REACT_APP_LEVEL_EXPERT;
const jwt = localStorage.getItem("token");
const apiRoot = process.env.REACT_APP_API_URL;

const SkeletonLoader = () => (
    <div className="items-stretch border-b-2 border-solid py-5">
        <div className="flex justify-between gap-2 pr-20">
            <div className="flex justify-between gap-1">
                <div className="skeleton skeleton-animation rounded-full w-8 h-8"></div>
                <div className="skeleton skeleton-animation text-sm font-medium leading-5 w-32 h-5 my-auto"></div>
            </div>
            <div className="flex gap-0 my-auto">
                <div className="skeleton skeleton-animation text-sm leading-5 w-24 h-5"></div>
            </div>
        </div>
        <div className="skeleton skeleton-animation text-sm leading-5 h-4 w-full my-3"></div>
    </div>
);

const ResponseCard = ({ expert_required, authLevel, slash_cost, response, endorse_cost, flared_already, topic_creator_address_id }) => {
    const [newResponseBody, setNewResponseBody] = useState()
    const [flared, setFlared] = useState();
    const [slashed, setSlashed] = useState();
    const [endorsed, setEndorsed] = useState();
    const [endorsementNames, setEndorsementNames] = useState('')
    const [editingResponse, setEditingResponse] = useState(false)

    useEffect(() => {
        if (response != null && response.endorsement_names != null) {
            const htmlString = response.endorsement_names.join('<br>');
            setEndorsementNames("<b>Endorsed by:</b><br />" + htmlString);
        }
    }, [response]);

    useEffect(() => {
        if (response != null && response.body != null) {
            setNewResponseBody(response.body)
        }
    }, [editingResponse]);

    const confirmAction = (action, cost) => {
        if (action === "flare") {
            return window.confirm("Are you sure you want to flare this response? Flaring is free but can only be used on one response per question");
        } else {
            const costText = `${cost} creds`;
            return window.confirm(`Are you sure you want to ${action} for ${costText}?`);
        }
    };

    /* Submit a response */
    const handleResponseUpdate = async (text) => {
        let confirmMessage = `Are you sure you want to update your response? All endorsements, flares, and slashes will be reset. `;

        const confirmResponse = window.confirm(confirmMessage);
        if (confirmResponse) {
            try {
                const jsonResponse = {
                    body: text
                }
                const netResponse = await fetch(apiRoot + '/response?response_id=' + response.id, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify(jsonResponse),
                });
                // Check if the response status is not OK (200-299)
                if (!netResponse.ok) {
                    const netResponseBody = await netResponse.text();
                    throw new Error(`HTTP error: ${netResponse.status} ${netResponse.statusText} ${netResponseBody}`);
                }
                window.location.reload();
            } catch (error) {
                toast.error(`Error submitting response: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    };

    const handleEngage = async (type, responseID, cost) => {
        if (!confirmAction(type, cost)) return;

        try {
            const response = await fetch(`${apiRoot}/response/${type}?response_id=${responseID}`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${jwt}` }
            });
            if (!response.ok) {
                const responseBody = await response.text();
                throw new Error(responseBody);
            }
            if (type === "flare") {
                setFlared(responseID);
            } else if (type === "slash") {
                setSlashed(responseID);
            } else if (type === "endorse") {
                setEndorsed(responseID);
            }
        } catch (error) {
            toast.error(`Error trying ${type}: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };


    if (!response) return <SkeletonLoader />;

    const FlareButton = ({ count, clickable, clicked, slashed }) => (
        <div>
            <div onClick={clickable && !clicked ? () => handleEngage("flare", response.id, 0) : undefined}
                className={`flex gap-1 p-2 rounded items-center justify-center transition duration-300 ease-in-out ${clicked ? "bg-green-800 text-slate-100" : "text-cyan-800"} ${clickable && !clicked ? "cursor-pointer bg-slate-200 hover:bg-green-800 hover:text-slate-100" : ""}`}
                data-tooltip-id={clicked ? "flared-tooltip" : "flare-explain"}
                data-tooltip-place="left">
                <Tooltip id={"flared-tooltip"} content={(`You have flared this`)} />
                <Tooltip id={"flare-explain"} content={`Flaring shows support by directing future revenue to a response`} />
                <FaArrowUp className="w-6 h-6 transition-all" />
                <div className="select-none text-center text-s font-bold text-inherit text-base">
                    {flared === response.id ? (count + 1) : count}
                </div>
            </div>
        </div>
    );

    const SlashButton = ({ clickable, clicked, slashed }) => (
        <div onClick={clickable && !clicked ? () => handleEngage("slash", response.id, slash_cost) : undefined}
            className={`flex gap-1 p-2 rounded bg-slate-200 transition duration-300 ease-in-out items-center justify-center ${clicked ? "text-slate-100 bg-red-800" : (clickable && !clicked ? "cursor-pointer hover:text-slate-100 text-cyan-800 hover:bg-red-800" : "")}`}
            data-tooltip-id="slash-tooltip"
            data-tooltip-html={clicked ? (`You have slashed this`) : (`Vote to slash this post for ${slash_cost} creds`)} data-tooltip-place="bottom">
            {clickable && <Tooltip id={"slash-tooltip"} />}
            <Tooltip id="slashed-tooltip" content="This response has been slashed and is no longer receiving revenue generated from this question" place="bottom" />
            <FaTimes className="w-6 h-6" />
        </div>
    );


    const EndorseButton = ({ count, clickable, clicked, slashed }) => (
        <div data-tooltip-id="endorsers-tooltip" data-tooltip-html={`${endorsementNames}`} data-tooltip-place="bottom">
            <Tooltip id="endorsers-tooltip" />
            <div onClick={clickable && !clicked ? () => handleEngage("endorse", response.id, endorse_cost) : undefined}
                className={`flex gap-1 p-2 rounded items-center justify-center transition duration-300 ease-in-out
                ${clicked ? "text-slate-100 bg-cyan-800" : (clickable && !clicked ? "cursor-pointer bg-slate-200 hover:text-white hover:text-slate-100 text-cyan-800 hover:bg-cyan-900" : "text-cyan-800")}`}
                data-tooltip-id={
                    clickable ?
                        (!clicked && ("endorse-tooltip")) :
                        (!clicked && (
                            slashed ? ("endorse-slashed-tooltip") : ("endorse-explain")))
                }>
                <Tooltip id="endorse-tooltip" html={`If you don't have anyhing else to add to the question <br /> endorse this post for ${endorse_cost} creds to share in future revenue`} place="top" />
                <Tooltip id="endorse-explain" content={"Endorsements are other specialists that have agreed with this response"} place="bottom" />
                <Tooltip id="endorse-slashed-tooltip" content="Slashed responses can not be engaged with" place="bottom" />
                <FaStar className={`${clicked ? "text-slate-100" : ""} w-6 h-6`} />
                <div className={`select-none text-center text-s font-bold text-inherit text-base`} >
                    {endorsed === response.id ? (count + 1) : count}
                </div>
            </div>
        </div>
    );


    const ReplyForm = ({ parentId = null, handleReply, setReplyingTo }) => {
        const handleSubmit = (text) => {
            handleReply(parentId, text);
        };

        return (
            <MarkdownTextBox
                initialText=""
                onSubmit={handleSubmit}
                onCancel={() => setReplyingTo(null)}
                placeholder="Type your reply here..."
                submitButtonText="Reply"
                toolbarItems={['bold', 'italic', 'link']}
            />
        );
    };

    const CommentComponent = ({ comment, depth = 0, owned, topic_creator_address_id, replyingTo, setReplyingTo, handleReply }) => {
        const [isEditing, setIsEditing] = useState(false);
        const [editedBody, setEditedBody] = useState(comment.body);

        const handleDelete = async () => {
            const isConfirmed = window.confirm("Are you sure you want to delete this reply?");
            if (!isConfirmed) return;

            try {
                const response = await fetch(`${apiRoot}/response/comment?comment_id=${comment.id}`, {
                    method: 'DELETE',
                    headers: { 'Authorization': `Bearer ${jwt}` }
                });
                if (!response.ok) {
                    throw new Error('Failed to delete comment');
                }
                const deletedComment = await response.json();
                // Update the comment state with the deleted comment
                comment.deleted = deletedComment.deleted;
                comment.body = deletedComment.body;
                // Force re-render
                setEditedBody(deletedComment.body);
                setIsEditing(false);
            } catch (error) {
                console.error('Error deleting comment:', error);
                toast.error(`Error deleting comment: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        const handleEdit = async (editedBody) => {
            try {
                const response = await fetch(`${apiRoot}/response/comment?comment_id=${comment.id}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${jwt}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ body: editedBody })
                });
                if (!response.ok) {
                    throw new Error('Failed to edit comment');
                }
                const updatedComment = await response.json();
                setIsEditing(false);
                // Update the comment in the parent component
                comment.body = updatedComment.body;
                // Force re-render
                setEditedBody(updatedComment.body);
            } catch (error) {
                console.error('Error editing comment:', error);
                toast.error(`Error editing comment: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };

        return (
            <div className={`mt-2 flex`} style={{ paddingLeft: `${depth * 1}rem` }}>
                <div className="flex-grow">
                    <div className="flex items-center gap-2 mb-1">
                        <div className="rounded-full object-contain object-center w-6 h-6 overflow-hidden shrink-0">
                            <ProfilePicture
                                userAddressId={comment.creator_address_id}
                                url={comment.creator_profile_picture_url}
                            />
                        </div>
                        <div className={`text-xs ${comment.creator_name ? 'text-zinc-800 font-medium' : 'italic text-zinc-500'} flex flex-wrap items-center gap-2`}>
                            {comment.creator_name ? comment.creator_name : (comment.creator_address_id ? "Pseudoanon" : "Anonymous")}
                            {comment.creator_address_id === topic_creator_address_id &&
                                <span className="bg-slate-200 select-none text-center rounded-full px-2 py-1">Original poster</span>}
                            {comment.creator_address_id == localStorage.getItem("user_address_id") &&
                                <span className="bg-cyan-800 select-none text-center rounded-full text-zinc-100 px-2 py-1">You</span>}
                            {comment.specialties && comment.specialties.length > 0 && (
                                comment.specialties.map((specialty, index) => {
                                    const hash = specialty.split('').reduce((acc, char) => {
                                        return char.charCodeAt(0) + ((acc << 5) - acc);
                                    }, 0);
                                    const hue = hash % 360;
                                    const backgroundColor = `hsl(${hue}, 70%, 80%)`;
                                    const textColor = `hsl(${hue}, 70%, 30%)`;

                                    return (
                                        <span
                                            key={index}
                                            className="px-2 py-1 select-none text-xs text-center font-semibold rounded-full flex items-center justify-center"
                                            style={{ backgroundColor, color: textColor }}
                                        >
                                            {specialty}
                                        </span>
                                    );
                                })
                            )}
                        </div>
                        <div className="text-neutral-500 text-xs">
                            <TimeSince timestamp={comment.created_at} />
                        </div>
                        {comment.body !== "Deleted" && (
                            <>
                                {comment.creator_address_id == localStorage.getItem("user_address_id") && (
                                    <>
                                    <div className="flex-grow"></div>
                                        <button
                                            onClick={() => setIsEditing(!isEditing)}
                                            className="text-cyan-800 text-xs hover:underline"
                                        >
                                            Edit
                                        </button>
                                    </>
                                )}
                                {owned && comment.creator_address_id != localStorage.getItem("user_address_id") && (
                                    <>
                                        <div className="flex-grow"></div>
                                        <button
                                            onClick={handleDelete}
                                            className="text-red-600 text-xs hover:underline"
                                        >
                                            Delete
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="flex">
                        <div className="w-[0.7rem] flex-shrink-0"></div>
                        <div className="text-zinc-800 text-sm mb-2 pl-2 border-l-2 border-slate-300 flex-grow">
                            <div className="flex flex-col">
                                {isEditing ? (
                                    <MarkdownTextBox
                                        initialText={editedBody}
                                        onSubmit={handleEdit}
                                        onCancel={() => setIsEditing(false)}
                                        onDelete={handleDelete}
                                        placeholder="Edit your comment..."
                                        submitButtonText="Save"
                                    />
                                ) : comment.body === "Deleted" ? (
                                    <i className="text-zinc-500">This comment has been removed</i>
                                ) : (
                                    <ReactMarkdown
                                        components={{
                                            img: ({node, ...props}) => (
                                                <img style={{maxHeight: '312px'}} {...props} />
                                            ),
                                        }}
                                    >
                                        {comment.body}
                                    </ReactMarkdown>
                                )}
                                {comment.body !== "Deleted" && replyingTo !== comment.id && !isEditing && (
                                    <button
                                        onClick={() => setReplyingTo(comment.id)}
                                        className="text-cyan-800 w-12 mt-2 border border-cyan-800 rounded"
                                    >
                                        Reply
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {replyingTo === comment.id && (
                        <div className="my-6">
                            <MarkdownTextBox
                                initialText=""
                                onSubmit={(text) => {
                                    handleReply(comment.id, text);
                                    setReplyingTo(null);
                                }}
                                onCancel={() => setReplyingTo(null)}
                                placeholder="Type your reply here..."
                                submitButtonText="Reply"
                            />
                        </div>
                    )}
                    {comment.children && comment.children.map(childComment => (
                        <CommentComponent
                            key={childComment.id}
                            comment={childComment}
                            depth={depth + 1}
                            owned={owned}
                            topic_creator_address_id={topic_creator_address_id}
                            handleReply={handleReply}
                            setReplyingTo={setReplyingTo}
                            replyingTo={replyingTo}
                        />
                    ))}
                </div>
            </div>
        );
    };



    const CommentSection = ({ comments: initialComments, response, topic_creator_address_id }) => {
        const [comments, setComments] = useState(initialComments || []);
        const [replyingTo, setReplyingTo] = useState(null);

        const handleReply = async (parentId = null, replyBody) => {
            try {
                const url = parentId
                    ? `${apiRoot}/response/comment?response_id=${response.id}&parent_id=${parentId}`
                    : `${apiRoot}/response/comment?response_id=${response.id}`;

                const jsonResponse = {
                    body: replyBody,
                };

                const netResponse = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                    body: JSON.stringify(jsonResponse),
                });

                if (!netResponse.ok) {
                    const netResponseBody = await netResponse.text();
                    throw new Error(`HTTP error: ${netResponse.status} ${netResponse.statusText} ${netResponseBody}`);
                }
                const newComment = await netResponse.json();

                // Update local state with the new comment
                setComments(prevComments => {
                    const updateCommentsRecursively = (comments) => {
                        return comments.map(comment => {
                            if (comment.id === parentId) {
                                return {
                                    ...comment,
                                    children: [...(comment.children || []), newComment]
                                };
                            } else if (comment.children) {
                                return {
                                    ...comment,
                                    children: updateCommentsRecursively(comment.children)
                                };
                            }
                            return comment;
                        });
                    };

                    if (parentId) {
                        return updateCommentsRecursively(prevComments);
                    } else {
                        return [...prevComments, newComment];
                    }
                });
                // Reset state
                setReplyingTo(null);

            } catch (error) {
                toast.error(`Error submitting comment: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        };



        return (
            <div className="flex gap-2 flex-col">
                <div className="flex mt-4 items-center">
                    {replyingTo !== 'response:' + response.id && (
                        <button
                            onClick={() => setReplyingTo('response:' + response.id)}
                            className="w-14 text-cyan-800 p-1 border bg-slate-200 border-cyan-800 rounded"
                        >
                            Reply
                        </button>
                    )}
                    <div className="flex-grow" />
                    <div className="flex gap-2">
                        <FlareButton count={response.flare_count} clickable={!response.slashed && ((!expert_required || authLevel != expertAuth) && !flared_already)} clicked={flared == response.id || response.has_flared} slashed={response.slashed} />
                        <EndorseButton clickable={!response.slashed && (!expert_required || authLevel == expertAuth)} clicked={endorsed == response.id || response.has_endorsed} count={response.endorsement_count} slashed={response.slashed} />
                        {(!expert_required || authLevel == expertAuth) &&
                            <SlashButton clickable={!response.slashed && (!expert_required || authLevel == expertAuth)} clicked={slashed == response.id || response.has_slashed} slashed={response.slashed} />
                        }
                    </div>
                </div>
                {replyingTo === 'response:' + response.id && <ReplyForm handleReply={handleReply} setReplyingTo={setReplyingTo} />}


                <div className="flex flex-col bg-slate-100 mt-4 pr-2 rounded">
                    {comments.length > 0 && (
                        <div className="p-4">
                            {comments.map(comment => (
                                <CommentComponent
                                    key={"comment-" + comment.id}
                                    response={response}
                                    comment={comment}
                                    replyingTo={replyingTo}
                                    setReplyingTo={setReplyingTo}
                                    handleReply={handleReply}
                                    topic_creator_address_id={topic_creator_address_id}
                                    owned={response.owned || comment.creator_address_id === localStorage.getItem("user_address_id")}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className={`items-stretch px-5 gap-8 ${response.slashed ? "bg-rose-200" : "bg-slate-50"} border-b-[color:var(--DD-Palette-Neutrals-Light,#E6E6E6)] flex-col py-5 border-b-2 border-solid max-md:max-w-full`}>
            <div className="flex-col w-full">
                <div className="flex flex-col flex-grow mr-4">
                    <div className="flex gap-2 flex-wrap items-center">
                        <div className="items-stretch flex justify-between gap-2">
                            <div className="rounded-full object-contain object-center w-8 h-8 justify-center items-center overflow-hidden shrink-0 max-w-full">
                                <ProfilePicture pseudoanon={false} url={response.creator_profile_picture_url} />
                            </div>
                            <div className="flex flex-wrap text-nowrap items-center gap-2 gap-y-4">
                                <div className={`text-xs ${response.creator_name ? 'text-zinc-800 font-medium' : 'italic text-zinc-500'} flex flex-wrap items-center gap-2`}>
                                    {response.creator_name ? response.creator_name : "Anonymous"}
                                    {response.creator_address_id === topic_creator_address_id &&
                                        <span className="bg-slate-200 select-none text-center rounded-full px-2 py-1">Original poster</span>}
                                    {response.owned &&
                                        <span className="bg-cyan-800 select-none text-center rounded-full text-zinc-100 px-2 py-1">You</span>}
                                    {response.specialties && response.specialties.length > 0 && (
                                        response.specialties.map((specialty, index) => {
                                            const hash = specialty.split('').reduce((acc, char) => {
                                                return char.charCodeAt(0) + ((acc << 5) - acc);
                                            }, 0);
                                            const hue = hash % 360;
                                            const backgroundColor = `hsl(${hue}, 70%, 80%)`;
                                            const textColor = `hsl(${hue}, 70%, 30%)`;

                                            return (
                                                <span
                                                    key={index}
                                                    className="px-2 py-1 select-none text-xs text-center font-semibold rounded-full flex items-center justify-center"
                                                    style={{ backgroundColor, color: textColor }}
                                                >
                                                    {specialty}
                                                </span>
                                            );
                                        })
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="items-stretch self-center flex gap-0 my-auto">
                            <div className="flex text-neutral-500 text-right text-sm leading-5 grow select-none whitespace-nowrap">
                                <TimeSince timestamp={response.created_at} />
                            </div>
                        </div>
                        {(response.owned) && (
                            <div
                                onClick={() => setEditingResponse(!editingResponse)}
                                data-tooltip-id="edit-tooltip"
                                className={`text-sm cursor-pointer hover:underline text-cyan-800`}
                            >
                                Edit
                                <Tooltip id="edit-tooltip" content="Editing your response will reset its reputation" place="left" className="select-none" />
                            </div>
                        )}
                    </div>
                    <div className="flex justify-between">
                        {editingResponse ? (
                            <div className="w-full my-3">
                                <MarkdownTextBox
                                    initialText={newResponseBody}
                                    onSubmit={handleResponseUpdate}
                                    onCancel={() => setEditingResponse(false)}
                                    placeholder="Edit your response..."
                                    submitButtonText="Submit edit"
                                />
                            </div>
                        ) : (
                            <div className="text-zinc-800 text-sm leading-5 my-3 max-md:max-w-full">
                                <div className="prose max-w-full">
                                    <ReactMarkdown
                                        components={{
                                            img: ({node, ...props}) => (
                                                <img style={{maxHeight: '312px'}} {...props} />
                                            ),
                                        }}
                                    >
                                        {response.body}
                                    </ReactMarkdown>
                                </div>
                            </div>
                        )}
                    </div>
                    {response.slashed &&
                        <div className="mt-4 text-red-950 text-sm">
                            <i><b>Note:</b> this response has been slashed by the specialists and will not receive future revenue from this quesiton</i>
                        </div>
                    }
                </div>
            </div>
            <CommentSection
                comments={response.comments}
                response={response}
                topic_creator_address_id={topic_creator_address_id}
            />
        </div>
    );
};

export default ResponseCard;
