import * as React from "react";
import ProfilePicture from "./ProfilePicture";
import { Tooltip } from "react-tooltip";
import { useAppContext } from '../AppContext';
import { Link, useNavigate } from 'react-router-dom';
import { FiUser, FiClock, FiSettings, FiLogOut } from 'react-icons/fi';

const ProfileDrawer = ({ isOpen, onClose }) => {
    const email = localStorage.getItem("email");
    const name = localStorage.getItem("name");
    const { balance } = useAppContext();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    return (
        <div 
            onClick={(e) => e.stopPropagation()} 
            className={`select-none bg-slate-50 rounded-lg sm:rounded-l-lg sm:rounded-r-none fixed top-0 right-0 w-full sm:w-96 shadow-2xl transform transition-transform ${isOpen ? 'translate-y-0' : '-translate-y-full'}`}
        >
            <div className="flex pt-28 flex-col p-6">
                <div className="space-y-6 mb-6">
                    <Link to="/profile" className="flex items-center space-x-4 text-zinc-600 hover:text-zinc-800" onClick={onClose}>
                        <FiUser className="text-gray-500" size={24} />
                        <span className="text-base font-medium">My Profile</span>
                    </Link>
                    <Link to="/history" className="flex items-center space-x-4 text-zinc-600 hover:text-zinc-800" onClick={onClose}>
                        <FiClock className="text-gray-500" size={24} />
                        <span className="text-base font-medium">Cred History</span>
                    </Link>
                    <div 
                        className="flex items-center space-x-4 text-zinc-600 hover:text-zinc-800 cursor-pointer"
                        onClick={() => {
                            handleLogout();
                        }}
                    >
                        <FiLogOut className="text-gray-500" size={24} />
                        <span className="text-base font-medium">Logout</span>
                    </div>
                </div>
                <div className="pt-6 border-t">
                    <div className="flex justify-center space-x-6 items-center">
                        <a href="https://discord.gg/Z5nYWyRe2h" target="_blank" rel="noopener noreferrer">
                            <img src="/discord-mark-black.svg" alt="Join us on Discord" className="h-8 w-8" />
                        </a>
                        <a href="https://twitter.com/divedeep_io" target="_blank" rel="noopener noreferrer">
                            <img src="/x-logo-black.png" alt="Join us on X" className="h-6 w-6" />
                        </a>
                        <div data-tooltip-id="contact-tooltip" data-tooltip-html="contact us with feedback regarding bugs or features" data-tooltip-place="top">
                            <a href="mailto:contact@divedeep.io" className="text-sm text-blue-600 hover:text-blue-800">
                                contact@divedeep.io
                            </a>
                            <Tooltip id="contact-tooltip" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileDrawer;