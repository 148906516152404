// AppContext.js
import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { isJwtExpired } from './components/login';

const AppContext = createContext();
const apiRoot = process.env.REACT_APP_API_URL;
const jwt = localStorage.getItem("token");

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    if (jwt !== null) {
        if (isJwtExpired(jwt)) {
            localStorage.removeItem("token");
        }
    };

    const [communityConfig, setCommunityConfig] = useState({});
    const [balance, setBalance] = useState(null);
    const [cachedResponses, setCachedResponses] = useState([]);

    const fetchCommunityConfig = useCallback(async () => {
        try {
            const apiUrl = apiRoot + '/communities';
            const response = await fetch(apiUrl, {
                method: 'GET'
            });

            if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
                const data = await response.json();
                setCommunityConfig(data);
            } else {
                throw new Error('Failed to fetch community config');
            }
        } catch (error) {
            toast.error(`Error loading community config: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, []);

    useEffect(() => {
        fetchCommunityConfig();
    }, [fetchCommunityConfig]);

    const filteredCommunityConfig = useMemo(() => {
        if (!communityConfig) return null;

        // Get the subdomain from the current URL
        const subdomain = window.location.hostname.split('.')[0];

        // Filter the communityConfig based on subdomain
        if (subdomain && subdomain !== 'app' && subdomain !== 'localdomain') {
            return Object.fromEntries(
                Object.entries(communityConfig).filter(([_, community]) => community.name.toLowerCase() === subdomain.toLowerCase())
            );
        } else {
            return communityConfig;
        }
    }, [communityConfig]);

    /* Load balance */
    useEffect(() => {
        const apiUrl = apiRoot + '/account/balance';

        const fetchBalance = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET', // *GET, POST, PUT, DELETE, etc.
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                });

                // Check if the response is ok and the content type is JSON
                if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
                    const data = await response.json();
                    setBalance(data.token_balance);
                }
            } catch (error) {
                toast.error(`Error loading balance: ${error.message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });;
            }
        };

        if (jwt !== null && localStorage.getItem('email_validated') === 'true') {
            fetchBalance();
        }
    }, [balance, jwt]);

    /* Fetch auth levels */
    useEffect(() => {
        const fetchAuthLevels = async () => {
            try {
                const response = await fetch(`${apiRoot}/account/auth_levels`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + jwt
                    },
                });

                if (response.ok && response.headers.get("content-type")?.includes("application/json")) {
                    const data = await response.json();
                    localStorage.setItem('auth_level', JSON.stringify(data.auth_levels));
                    localStorage.setItem('email_validated', JSON.stringify(data.email_validated));
                } else {
                    throw new Error('Failed to fetch auth levels');
                }
            } catch (error) {
            }
        };

        if (jwt !== null) {
            fetchAuthLevels();
        }
    }, [jwt]);

    // Provide an object to the value prop with all your state and setters
    const contextValue = {
        balance,
        setBalance,
        communityConfig: filteredCommunityConfig,
        cachedResponses,
        setCachedResponses,
        // Include other state variables and setters here
    };

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};