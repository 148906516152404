import { React } from 'react';
import Navbar from './navbar';
import { useAppContext } from '../AppContext';
import { ToastContainer } from 'react-toastify';

const jwt = localStorage.getItem("token");

const Layout = ({ children }) => {
    const { balance } = useAppContext();
    return (
        <>

            <div className="flex flex-col overflow-hidden bg-[#d9d9d9] w-full h-full min-h-screen items-center">
                <div className="fixed top-0 bg-slate-900 left-0 w-full h-full bg-no-repeat bg-cover bg-center">
                    <img src="/wave.webp" alt="bg" className="opacity-5 w-full h-full object-cover" />
                </div>
                <Navbar />
                <ToastContainer />
                <div className="flex relative justify-center pt-20 w-full">
                    <div className="flex flex-wrap-reverse w-full max-w-6xl sm:gap-5">
                        <div className="flex flex-col grow items-stretch min-h-screen w-full lg:w-1/2">
                            {children}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Layout;