import React, { useState, useEffect } from 'react';

const TimeSince = ({ timestamp }) => {
    const [timeSince, setTimeSince] = useState(<div className="skeleton skeleton-animation text-sm leading-5 w-12 h-5"></div>);

    useEffect(() => {
        const calculateTimeSince = () => {
            const now = new Date();
            const past = new Date(timestamp);
            const diffInSeconds = Math.floor((now - past) / 1000);

            if (diffInSeconds < 60) {
                return `Just now`;
            } else if (diffInSeconds < 3600) {
                return `${Math.floor(diffInSeconds / 60)}m`;
            } else if (diffInSeconds < 86400) {
                return `${Math.floor(diffInSeconds / 3600)}h`;
            } else {
                return `${Math.floor(diffInSeconds / 86400)}d`;
            }
        };

        setTimeSince(calculateTimeSince());

        const getUpdateInterval = (diffInSeconds) => {
            if (diffInSeconds < 3600) return 60000; // Update every minute
            if (diffInSeconds < 86400) return 3600000; // Update every hour
            return 86400000; // Update every day
        };

        const interval = setInterval(() => {
            const diffInSeconds = Math.floor((new Date() - new Date(timestamp)) / 1000);
            setTimeSince(calculateTimeSince());
            clearInterval(interval); // Clear the current interval
            setInterval(calculateTimeSince, getUpdateInterval(diffInSeconds)); // Set new interval
        }, getUpdateInterval(Math.floor((new Date() - new Date(timestamp)) / 1000)));

        return () => clearInterval(interval);
    }, [timestamp]);

    return <div>{timeSince}</div>;
};

export default TimeSince;