import * as React from "react";
import { toast } from 'react-toastify';
import { Tooltip } from "react-tooltip";
import { useState, useEffect } from 'react';
import { useAppContext } from '../AppContext';
import { IoIosInformationCircle } from "react-icons/io";
import MarkdownTextBox from "./textBox";
import Select from 'react-select'

const apiRoot = process.env.REACT_APP_API_URL;
const jwt = localStorage.getItem("token");

const UpdateTopicModal = ({ topic, onClose }) => {
    const { communityConfig, balance } = useAppContext();
    const [selectedCommunity, setSelectedCommunity] = useState(topic.subdao_id);

    const [questionTitle, setQuestionTitle] = useState(topic.title);
    const [questionBody, setQuestionBody] = useState(topic.body);
    const [questionMinResponders, setQuestionMinResponders] = useState(topic.min_response);
    const [topicCreateCost, setTopicCreateCost] = useState(selectedCommunity ? communityConfig[selectedCommunity].topic_create_cost : 0)
    const [questionBounty, setQuestionBounty] = useState(topic.bounty);

    const [topicCostDiff, setTopicCostDiff] = useState(0)
    const [bountyCostDiff, setBountyCostDiff] = useState(0)
    useEffect(() => {
        setTopicCreateCost(communityConfig[selectedCommunity].topic_create_cost)
        setTopicCostDiff(communityConfig[topic.subdao_id].topic_create_cost - communityConfig[selectedCommunity].topic_create_cost)
        setBountyCostDiff(topic.bounty - questionBounty)
    }, [selectedCommunity, questionBounty]);

    const maxBounty = Math.floor(balance - topicCreateCost); // Cap for bounty
    const totalExperts = Object.values(communityConfig).reduce((acc, { expert_count }) => acc + expert_count, 0);
    const handleQuestionUpdate = async (e) => {
        const jwt = localStorage.getItem("token");
        e.preventDefault();
        try {
            const jsonQuestion = {
                title: questionTitle,
                body: questionBody,
                min_response: questionMinResponders,
                bounty: questionBounty,
                subdao_id: typeof selectedCommunity === 'string' ? parseInt(selectedCommunity, 10) : selectedCommunity,
            }

            const response = await fetch(apiRoot + '/topic?topic_id=' + topic.id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + jwt
                },
                body: JSON.stringify(jsonQuestion),
            });
            if (!response.ok) {
                const responseBody = await response.text();
                throw new Error(`HTTP error: ${response.status} ${response.statusText} ${responseBody}`);
            }
            onClose();
        } catch (error) {
            toast.error(`Error asking question: ${error.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };


    return (
        <div className="items-stretch bg-white flex sm:w-[36rem] flex-col justify-center py-6 rounded-2xl">
            <>
                <div className="mb-6 text-black text-center text-2xl leading-9 max-md:max-w-full">
                    {selectedCommunity ? (communityConfig[selectedCommunity].expert_auth_required ? <span>Ask all <b>{communityConfig[selectedCommunity].expert_count}</b> specialists in the</span> : <span>Ask <b>anyone</b> in the</span>) : <span>Ask <b>{totalExperts}</b> specialists across the platform</span>}
                    <Select
                        className="text-cyan-700 font-extrabold bg-transparent border-none cursor-pointer outline-none transition-colors p-1 duration-500 ease-in-out hover:bg-cyan-700 hover:bg-opacity-10 rounded-xl ml-2"
                        value={selectedCommunity ? { value: selectedCommunity, label: communityConfig[selectedCommunity].name } : ''}
                        onChange={(option) => setSelectedCommunity(option.value)}
                        options={communityConfig ? Object.keys(communityConfig).map((id) => ({ value: id, label: communityConfig[id].name })) : []}
                        placeholder="Select a community"
                    />
                </div>
            </>
            <form onSubmit={handleQuestionUpdate} className="space-y-4">
                <div className="space-y-4">
                    <div>
                        <label htmlFor="question" className="block text-xl font-bold text-gray-700">Title</label>
                        <input type="text" id="question" name="question" value={questionTitle} autoComplete="off" onChange={(e) => setQuestionTitle(e.target.value)} className="border border-gray-300 border-solid rounded-lg p-2 mt-1 block w-full shadow-sm" />
                    </div>
                    <div>
                        <label htmlFor="context" className="block text-xl mb-3 font-bold text-gray-700">Additional context</label>
                        <MarkdownTextBox
                            form={false}
                            initialText={questionBody}
                            onChange={(text) => setQuestionBody(text)}
                            onSubmit={(text) => {
                                setQuestionBody(text);
                            }}
                            placeholder="Type your context here"
                            toolbarItems={['bold', 'italic', 'unordered-list', 'ordered-list', 'link', 'quote', 'image']}
                            submitButtonText="Update"
                        />
                    </div>
                </div>
                <div className="block text-xl font-bold text-gray-700">
                    Set your tip
                </div>
                <div className="items-stretch border border-[color:var(--Neutrals-100,#E0E0E0)] bg-slate-100 flex flex-col mt-2 p-4 rounded-lg border-solid max-md:max-w-full">
                    <div className="grid grid-flow-row flex-wrap justify-stretch items-center rounded-xl p-2 gap-6 sm:p-10">
                        <div className="w-full">
                            <label htmlFor="responders" className="block text-md font-medium text-gray-700">
                                Minimum responders:
                            </label>
                            <input type="range" id="responders" name="responders" min="1" max="10" value={questionMinResponders} onChange={(e) => setQuestionMinResponders(parseInt(e.target.value, 10))} className="mt-1 w-full" />
                            <input type="number" id="respondersInput" name="respondersInput" min="1" value={questionMinResponders} onChange={(e) => setQuestionMinResponders(parseInt(e.target.value, 10))} className="m-auto p-4 w-full mt-4 block text-center border-gray-300 rounded-md shadow-sm" />
                        </div>
                        <div className="w-full">
                            <label htmlFor="bounty" className="block text-md font-medium text-gray-700">
                                Tip:
                            </label>
                            <input type="range" id="bounty" name="bounty" min="0" max={100} step={questionMinResponders} value={questionBounty} onChange={(e) => setQuestionBounty(Math.min(parseFloat(e.target.value), maxBounty))} className="mt-1 w-full" />
                            <input type="number" id="bountyInput" name="bountyInput" min="0" max={maxBounty} step={questionMinResponders} value={questionBounty} onChange={(e) => setQuestionBounty(Math.min(parseFloat(e.target.value), maxBounty))} className="m-auto text-center p-4 w-full mt-4 block border-gray-300 rounded-md shadow-sm" />
                        </div>
                        <div className="flex flex-col text-center sm:text-right w-full">
                            <div>
                                The first <b>{questionMinResponders === 1 ? (<span>expert</span>) : (<span>{questionMinResponders} experts </span>)}</b> to answer <br></br>
                                will get <b>{Math.floor(100 * ((questionBounty + topicCreateCost) / questionMinResponders)) / 100}</b> creds
                                {questionMinResponders === 1 ? (<span></span>) : (<span> each</span>)}
                            </div>
                            <div className="flex mt-2 justify-end">
                                <IoIosInformationCircle data-tooltip-id="calculation-tooltip" className="fill-slate-400 h-6 w-6" />
                                <Tooltip id="calculation-tooltip" content="This number includes the cost to create the question" place="left">
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex gap-2">
                    <button type="submit" className="flex flex-wrap p-6 gap-4 items-center justify-around w-1/2 sm:w-2/3 bg-cyan-950 text-white rounded-lg hover:bg-cyan-800 focus:outline-none">
                        <div>
                            <b className='text-xl select-none'>Update your question</b>
                        </div>
                        {(topicCostDiff + bountyCostDiff) > 0 && (<div><span className="font-bold text-green-500">refund {Math.abs(topicCostDiff + bountyCostDiff)} creds</span></div>)}
                        {(topicCostDiff + bountyCostDiff) < 0 && (<div><span className="font-bold text-red-500">pay {Math.abs(topicCostDiff + bountyCostDiff)} creds</span></div>)}
                    </button>
                    <div className="flex border border-[color:var(--Neutrals-100,#E0E0E0)] border-solid bg-slate-100 rounded-lg justify-center w-1/2 sm:w-1/3 items-center">
                        <div className="flex flex-col gap-4 p-6">
                            {
                                (topicCostDiff + bountyCostDiff == 0) ? (<div>No cost difference!</div>) :
                                    (
                                        <>
                                            {(topicCostDiff > 0) && (<div><span> ask cost decrease: </span><span className="text-green-500">{-topicCostDiff}</span></div>)}
                                            {(topicCostDiff < 0) && (<div><span>ask cost increase: </span><span className="text-red-500">+{-topicCostDiff} </span></div>)}
                                            {(bountyCostDiff > 0) && (<div><span>bounty decrease: </span><span className="text-green-500">{-bountyCostDiff} </span></div>)}
                                            {(bountyCostDiff < 0) && (<div><span>bounty increase: </span><span className="text-red-500">+{-bountyCostDiff}</span></div>)}
                                        </>
                                    )

                            }


                        </div>
                    </div>
                </div>
            </form>
        </div >
    );
};

export default UpdateTopicModal;
